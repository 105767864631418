/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiQueueUpdateResponse,
    ApiQueueUpdateResponseFromJSON,
    ApiQueueUpdateResponseFromJSONTyped,
    ApiQueueUpdateResponseToJSON,
} from './ApiQueueUpdateResponse';

/**
 * 
 * @export
 * @interface ApiBatchQueueUpdateResponse
 */
export interface ApiBatchQueueUpdateResponse {
    /**
     * 
     * @type {Array<ApiQueueUpdateResponse>}
     * @memberof ApiBatchQueueUpdateResponse
     */
    failedQueues?: Array<ApiQueueUpdateResponse>;
}

export function ApiBatchQueueUpdateResponseFromJSON(json: any): ApiBatchQueueUpdateResponse {
    return ApiBatchQueueUpdateResponseFromJSONTyped(json, false);
}

export function ApiBatchQueueUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBatchQueueUpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failedQueues': !exists(json, 'failedQueues') ? undefined : ((json['failedQueues'] as Array<any>).map(ApiQueueUpdateResponseFromJSON)),
    };
}

export function ApiBatchQueueUpdateResponseToJSON(value?: ApiBatchQueueUpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failedQueues': value.failedQueues === undefined ? undefined : ((value.failedQueues as Array<any>).map(ApiQueueUpdateResponseToJSON)),
    };
}

