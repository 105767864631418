/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/binoculars/binoculars.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1PodLogOptions
 */
export interface V1PodLogOptions {
    /**
     * APIVersion defines the versioned schema of this representation of an object.
     * Servers should convert recognized schemas to the latest internal value, and
     * may reject unrecognized values.
     * More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * +optional
     * @type {string}
     * @memberof V1PodLogOptions
     */
    apiVersion?: string;
    /**
     * The container for which to stream logs. Defaults to only container if there is one container in the pod.
     * +optional
     * @type {string}
     * @memberof V1PodLogOptions
     */
    container?: string;
    /**
     * Follow the log stream of the pod. Defaults to false.
     * +optional
     * @type {boolean}
     * @memberof V1PodLogOptions
     */
    follow?: boolean;
    /**
     * insecureSkipTLSVerifyBackend indicates that the apiserver should not confirm the validity of the
     * serving certificate of the backend it is connecting to.  This will make the HTTPS connection between the apiserver
     * and the backend insecure. This means the apiserver cannot verify the log data it is receiving came from the real
     * kubelet.  If the kubelet is configured to verify the apiserver's TLS credentials, it does not mean the
     * connection to the real kubelet is vulnerable to a man in the middle attack (e.g. an attacker could not intercept
     * the actual log data coming from the real kubelet).
     * +optional
     * @type {boolean}
     * @memberof V1PodLogOptions
     */
    insecureSkipTLSVerifyBackend?: boolean;
    /**
     * Kind is a string value representing the REST resource this object represents.
     * Servers may infer this from the endpoint the client submits requests to.
     * Cannot be updated.
     * In CamelCase.
     * More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * +optional
     * @type {string}
     * @memberof V1PodLogOptions
     */
    kind?: string;
    /**
     * If set, the number of bytes to read from the server before terminating the
     * log output. This may not display a complete final line of logging, and may return
     * slightly more or slightly less than the specified limit.
     * +optional
     * @type {number}
     * @memberof V1PodLogOptions
     */
    limitBytes?: number;
    /**
     * Return previous terminated container logs. Defaults to false.
     * +optional
     * @type {boolean}
     * @memberof V1PodLogOptions
     */
    previous?: boolean;
    /**
     * A relative time in seconds before the current time from which to show logs. If this value
     * precedes the time a pod was started, only logs since the pod start will be returned.
     * If this value is in the future, no logs will be returned.
     * Only one of sinceSeconds or sinceTime may be specified.
     * +optional
     * @type {number}
     * @memberof V1PodLogOptions
     */
    sinceSeconds?: number;
    /**
     * Programs using times should typically store and pass them as values,
     * not pointers. That is, time variables and struct fields should be of
     * type time.Time, not *time.Time.
     * 
     * A Time value can be used by multiple goroutines simultaneously except
     * that the methods GobDecode, UnmarshalBinary, UnmarshalJSON and
     * UnmarshalText are not concurrency-safe.
     * 
     * Time instants can be compared using the Before, After, and Equal methods.
     * The Sub method subtracts two instants, producing a Duration.
     * The Add method adds a Time and a Duration, producing a Time.
     * 
     * The zero value of type Time is January 1, year 1, 00:00:00.000000000 UTC.
     * As this time is unlikely to come up in practice, the IsZero method gives
     * a simple way of detecting a time that has not been initialized explicitly.
     * 
     * Each Time has associated with it a Location, consulted when computing the
     * presentation form of the time, such as in the Format, Hour, and Year methods.
     * The methods Local, UTC, and In return a Time with a specific location.
     * Changing the location in this way changes only the presentation; it does not
     * change the instant in time being denoted and therefore does not affect the
     * computations described in earlier paragraphs.
     * 
     * Representations of a Time value saved by the GobEncode, MarshalBinary,
     * MarshalJSON, and MarshalText methods store the Time.Location's offset, but not
     * the location name. They therefore lose information about Daylight Saving Time.
     * 
     * In addition to the required “wall clock” reading, a Time may contain an optional
     * reading of the current process's monotonic clock, to provide additional precision
     * for comparison or subtraction.
     * See the “Monotonic Clocks” section in the package documentation for details.
     * 
     * Note that the Go == operator compares not just the time instant but also the
     * Location and the monotonic clock reading. Therefore, Time values should not
     * be used as map or database keys without first guaranteeing that the
     * identical Location has been set for all values, which can be achieved
     * through use of the UTC or Local method, and that the monotonic clock reading
     * has been stripped by setting t = t.Round(0). In general, prefer t.Equal(u)
     * to t == u, since t.Equal uses the most accurate comparison available and
     * correctly handles the case when only one of its arguments has a monotonic
     * clock reading.
     * @type {Date}
     * @memberof V1PodLogOptions
     */
    sinceTime?: Date;
    /**
     * If set, the number of lines from the end of the logs to show. If not specified,
     * logs are shown from the creation of the container or sinceSeconds or sinceTime
     * +optional
     * @type {number}
     * @memberof V1PodLogOptions
     */
    tailLines?: number;
    /**
     * If true, add an RFC3339 or RFC3339Nano timestamp at the beginning of every line
     * of log output. Defaults to false.
     * +optional
     * @type {boolean}
     * @memberof V1PodLogOptions
     */
    timestamps?: boolean;
}

export function V1PodLogOptionsFromJSON(json: any): V1PodLogOptions {
    return V1PodLogOptionsFromJSONTyped(json, false);
}

export function V1PodLogOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PodLogOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': !exists(json, 'apiVersion') ? undefined : json['apiVersion'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'follow': !exists(json, 'follow') ? undefined : json['follow'],
        'insecureSkipTLSVerifyBackend': !exists(json, 'insecureSkipTLSVerifyBackend') ? undefined : json['insecureSkipTLSVerifyBackend'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'limitBytes': !exists(json, 'limitBytes') ? undefined : json['limitBytes'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'sinceSeconds': !exists(json, 'sinceSeconds') ? undefined : json['sinceSeconds'],
        'sinceTime': !exists(json, 'sinceTime') ? undefined : (new Date(json['sinceTime'])),
        'tailLines': !exists(json, 'tailLines') ? undefined : json['tailLines'],
        'timestamps': !exists(json, 'timestamps') ? undefined : json['timestamps'],
    };
}

export function V1PodLogOptionsToJSON(value?: V1PodLogOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': value.apiVersion,
        'container': value.container,
        'follow': value.follow,
        'insecureSkipTLSVerifyBackend': value.insecureSkipTLSVerifyBackend,
        'kind': value.kind,
        'limitBytes': value.limitBytes,
        'previous': value.previous,
        'sinceSeconds': value.sinceSeconds,
        'sinceTime': value.sinceTime === undefined ? undefined : (value.sinceTime.toISOString()),
        'tailLines': value.tailLines,
        'timestamps': value.timestamps,
    };
}

