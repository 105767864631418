/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * DEPRECATED: GitRepo is deprecated. To provision a container with a git repo, mount an
 * EmptyDir into an InitContainer that clones the repo using git, then mount the EmptyDir
 * into the Pod's container.
 * @export
 * @interface V1GitRepoVolumeSource
 */
export interface V1GitRepoVolumeSource {
    /**
     * Target directory name.
     * Must not contain or start with '..'.  If '.' is supplied, the volume directory will be the
     * git repository.  Otherwise, if specified, the volume will contain the git repository in
     * the subdirectory with the given name.
     * +optional
     * @type {string}
     * @memberof V1GitRepoVolumeSource
     */
    directory?: string;
    /**
     * Repository URL
     * @type {string}
     * @memberof V1GitRepoVolumeSource
     */
    repository?: string;
    /**
     * Commit hash for the specified revision.
     * +optional
     * @type {string}
     * @memberof V1GitRepoVolumeSource
     */
    revision?: string;
}

export function V1GitRepoVolumeSourceFromJSON(json: any): V1GitRepoVolumeSource {
    return V1GitRepoVolumeSourceFromJSONTyped(json, false);
}

export function V1GitRepoVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GitRepoVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directory': !exists(json, 'directory') ? undefined : json['directory'],
        'repository': !exists(json, 'repository') ? undefined : json['repository'],
        'revision': !exists(json, 'revision') ? undefined : json['revision'],
    };
}

export function V1GitRepoVolumeSourceToJSON(value?: V1GitRepoVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directory': value.directory,
        'repository': value.repository,
        'revision': value.revision,
    };
}

