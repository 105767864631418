/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1HTTPIngressRuleValue,
    V1HTTPIngressRuleValueFromJSON,
    V1HTTPIngressRuleValueFromJSONTyped,
    V1HTTPIngressRuleValueToJSON,
} from './V1HTTPIngressRuleValue';

/**
 * IngressRuleValue represents a rule to apply against incoming requests. If the
 * rule is satisfied, the request is routed to the specified backend. Currently
 * mixing different types of rules in a single Ingress is disallowed, so exactly
 * one of the following must be set.
 * @export
 * @interface V1IngressRuleValue
 */
export interface V1IngressRuleValue {
    /**
     * 
     * @type {V1HTTPIngressRuleValue}
     * @memberof V1IngressRuleValue
     */
    http?: V1HTTPIngressRuleValue;
}

export function V1IngressRuleValueFromJSON(json: any): V1IngressRuleValue {
    return V1IngressRuleValueFromJSONTyped(json, false);
}

export function V1IngressRuleValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1IngressRuleValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'http': !exists(json, 'http') ? undefined : V1HTTPIngressRuleValueFromJSON(json['http']),
    };
}

export function V1IngressRuleValueToJSON(value?: V1IngressRuleValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'http': V1HTTPIngressRuleValueToJSON(value.http),
    };
}

