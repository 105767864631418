/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookoutJobInfo,
    LookoutJobInfoFromJSON,
    LookoutJobInfoFromJSONTyped,
    LookoutJobInfoToJSON,
} from './LookoutJobInfo';

/**
 * 
 * @export
 * @interface LookoutQueueInfo
 */
export interface LookoutQueueInfo {
    /**
     * 
     * @type {number}
     * @memberof LookoutQueueInfo
     */
    jobsPending?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutQueueInfo
     */
    jobsQueued?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutQueueInfo
     */
    jobsRunning?: number;
    /**
     * 
     * @type {string}
     * @memberof LookoutQueueInfo
     */
    longestRunningDuration?: string;
    /**
     * 
     * @type {LookoutJobInfo}
     * @memberof LookoutQueueInfo
     */
    longestRunningJob?: LookoutJobInfo;
    /**
     * 
     * @type {string}
     * @memberof LookoutQueueInfo
     */
    oldestQueuedDuration?: string;
    /**
     * 
     * @type {LookoutJobInfo}
     * @memberof LookoutQueueInfo
     */
    oldestQueuedJob?: LookoutJobInfo;
    /**
     * 
     * @type {string}
     * @memberof LookoutQueueInfo
     */
    queue?: string;
}

export function LookoutQueueInfoFromJSON(json: any): LookoutQueueInfo {
    return LookoutQueueInfoFromJSONTyped(json, false);
}

export function LookoutQueueInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutQueueInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobsPending': !exists(json, 'jobsPending') ? undefined : json['jobsPending'],
        'jobsQueued': !exists(json, 'jobsQueued') ? undefined : json['jobsQueued'],
        'jobsRunning': !exists(json, 'jobsRunning') ? undefined : json['jobsRunning'],
        'longestRunningDuration': !exists(json, 'longestRunningDuration') ? undefined : json['longestRunningDuration'],
        'longestRunningJob': !exists(json, 'longestRunningJob') ? undefined : LookoutJobInfoFromJSON(json['longestRunningJob']),
        'oldestQueuedDuration': !exists(json, 'oldestQueuedDuration') ? undefined : json['oldestQueuedDuration'],
        'oldestQueuedJob': !exists(json, 'oldestQueuedJob') ? undefined : LookoutJobInfoFromJSON(json['oldestQueuedJob']),
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
    };
}

export function LookoutQueueInfoToJSON(value?: LookoutQueueInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobsPending': value.jobsPending,
        'jobsQueued': value.jobsQueued,
        'jobsRunning': value.jobsRunning,
        'longestRunningDuration': value.longestRunningDuration,
        'longestRunningJob': LookoutJobInfoToJSON(value.longestRunningJob),
        'oldestQueuedDuration': value.oldestQueuedDuration,
        'oldestQueuedJob': LookoutJobInfoToJSON(value.oldestQueuedJob),
        'queue': value.queue,
    };
}

