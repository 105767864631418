/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiQueueCreateResponse,
    ApiQueueCreateResponseFromJSON,
    ApiQueueCreateResponseFromJSONTyped,
    ApiQueueCreateResponseToJSON,
} from './ApiQueueCreateResponse';

/**
 * 
 * @export
 * @interface ApiBatchQueueCreateResponse
 */
export interface ApiBatchQueueCreateResponse {
    /**
     * 
     * @type {Array<ApiQueueCreateResponse>}
     * @memberof ApiBatchQueueCreateResponse
     */
    failedQueues?: Array<ApiQueueCreateResponse>;
}

export function ApiBatchQueueCreateResponseFromJSON(json: any): ApiBatchQueueCreateResponse {
    return ApiBatchQueueCreateResponseFromJSONTyped(json, false);
}

export function ApiBatchQueueCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBatchQueueCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failedQueues': !exists(json, 'failedQueues') ? undefined : ((json['failedQueues'] as Array<any>).map(ApiQueueCreateResponseFromJSON)),
    };
}

export function ApiBatchQueueCreateResponseToJSON(value?: ApiBatchQueueCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failedQueues': value.failedQueues === undefined ? undefined : ((value.failedQueues as Array<any>).map(ApiQueueCreateResponseToJSON)),
    };
}

