/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiServiceType,
    ApiServiceTypeFromJSON,
    ApiServiceTypeFromJSONTyped,
    ApiServiceTypeToJSON,
} from './ApiServiceType';

/**
 * 
 * @export
 * @interface ApiServiceConfig
 */
export interface ApiServiceConfig {
    /**
     * 
     * @type {Array<number>}
     * @memberof ApiServiceConfig
     */
    ports?: Array<number>;
    /**
     * 
     * @type {ApiServiceType}
     * @memberof ApiServiceConfig
     */
    type?: ApiServiceType;
}

export function ApiServiceConfigFromJSON(json: any): ApiServiceConfig {
    return ApiServiceConfigFromJSONTyped(json, false);
}

export function ApiServiceConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServiceConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ports': !exists(json, 'ports') ? undefined : json['ports'],
        'type': !exists(json, 'type') ? undefined : ApiServiceTypeFromJSON(json['type']),
    };
}

export function ApiServiceConfigToJSON(value?: ApiServiceConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ports': value.ports,
        'type': ApiServiceTypeToJSON(value.type),
    };
}

