/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiJob,
    ApiJobFromJSON,
    ApiJobFromJSONTyped,
    ApiJobToJSON,
} from './ApiJob';
import {
    LookoutRunInfo,
    LookoutRunInfoFromJSON,
    LookoutRunInfoFromJSONTyped,
    LookoutRunInfoToJSON,
} from './LookoutRunInfo';

/**
 * 
 * @export
 * @interface LookoutJobInfo
 */
export interface LookoutJobInfo {
    /**
     * 
     * @type {Date}
     * @memberof LookoutJobInfo
     */
    cancelled?: Date;
    /**
     * 
     * @type {ApiJob}
     * @memberof LookoutJobInfo
     */
    job?: ApiJob;
    /**
     * 
     * @type {string}
     * @memberof LookoutJobInfo
     */
    jobJson?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutJobInfo
     */
    jobState?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutJobInfo
     */
    jobStateDuration?: string;
    /**
     * 
     * @type {Array<LookoutRunInfo>}
     * @memberof LookoutJobInfo
     */
    runs?: Array<LookoutRunInfo>;
}

export function LookoutJobInfoFromJSON(json: any): LookoutJobInfo {
    return LookoutJobInfoFromJSONTyped(json, false);
}

export function LookoutJobInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutJobInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancelled': !exists(json, 'cancelled') ? undefined : (new Date(json['cancelled'])),
        'job': !exists(json, 'job') ? undefined : ApiJobFromJSON(json['job']),
        'jobJson': !exists(json, 'jobJson') ? undefined : json['jobJson'],
        'jobState': !exists(json, 'jobState') ? undefined : json['jobState'],
        'jobStateDuration': !exists(json, 'jobStateDuration') ? undefined : json['jobStateDuration'],
        'runs': !exists(json, 'runs') ? undefined : ((json['runs'] as Array<any>).map(LookoutRunInfoFromJSON)),
    };
}

export function LookoutJobInfoToJSON(value?: LookoutJobInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancelled': value.cancelled === undefined ? undefined : (value.cancelled.toISOString()),
        'job': ApiJobToJSON(value.job),
        'jobJson': value.jobJson,
        'jobState': value.jobState,
        'jobStateDuration': value.jobStateDuration,
        'runs': value.runs === undefined ? undefined : ((value.runs as Array<any>).map(LookoutRunInfoToJSON)),
    };
}

