/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1PhotonPersistentDiskVolumeSource
 */
export interface V1PhotonPersistentDiskVolumeSource {
    /**
     * Filesystem type to mount.
     * Must be a filesystem type supported by the host operating system.
     * Ex. "ext4", "xfs", "ntfs". Implicitly inferred to be "ext4" if unspecified.
     * @type {string}
     * @memberof V1PhotonPersistentDiskVolumeSource
     */
    fsType?: string;
    /**
     * ID that identifies Photon Controller persistent disk
     * @type {string}
     * @memberof V1PhotonPersistentDiskVolumeSource
     */
    pdID?: string;
}

export function V1PhotonPersistentDiskVolumeSourceFromJSON(json: any): V1PhotonPersistentDiskVolumeSource {
    return V1PhotonPersistentDiskVolumeSourceFromJSONTyped(json, false);
}

export function V1PhotonPersistentDiskVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PhotonPersistentDiskVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsType': !exists(json, 'fsType') ? undefined : json['fsType'],
        'pdID': !exists(json, 'pdID') ? undefined : json['pdID'],
    };
}

export function V1PhotonPersistentDiskVolumeSourceToJSON(value?: V1PhotonPersistentDiskVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsType': value.fsType,
        'pdID': value.pdID,
    };
}

