/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1ResourceRequirements
 */
export interface V1ResourceRequirements {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof V1ResourceRequirements
     */
    limits?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof V1ResourceRequirements
     */
    requests?: { [key: string]: string; };
}

export function V1ResourceRequirementsFromJSON(json: any): V1ResourceRequirements {
    return V1ResourceRequirementsFromJSONTyped(json, false);
}

export function V1ResourceRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ResourceRequirements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limits': !exists(json, 'limits') ? undefined : json['limits'],
        'requests': !exists(json, 'requests') ? undefined : json['requests'],
    };
}

export function V1ResourceRequirementsToJSON(value?: V1ResourceRequirements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limits': value.limits,
        'requests': value.requests,
    };
}

