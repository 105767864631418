/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ConfigMapProjection,
    V1ConfigMapProjectionFromJSON,
    V1ConfigMapProjectionFromJSONTyped,
    V1ConfigMapProjectionToJSON,
} from './V1ConfigMapProjection';
import {
    V1DownwardAPIProjection,
    V1DownwardAPIProjectionFromJSON,
    V1DownwardAPIProjectionFromJSONTyped,
    V1DownwardAPIProjectionToJSON,
} from './V1DownwardAPIProjection';
import {
    V1SecretProjection,
    V1SecretProjectionFromJSON,
    V1SecretProjectionFromJSONTyped,
    V1SecretProjectionToJSON,
} from './V1SecretProjection';
import {
    V1ServiceAccountTokenProjection,
    V1ServiceAccountTokenProjectionFromJSON,
    V1ServiceAccountTokenProjectionFromJSONTyped,
    V1ServiceAccountTokenProjectionToJSON,
} from './V1ServiceAccountTokenProjection';

/**
 * Projection that may be projected along with other supported volume types
 * @export
 * @interface V1VolumeProjection
 */
export interface V1VolumeProjection {
    /**
     * 
     * @type {V1ConfigMapProjection}
     * @memberof V1VolumeProjection
     */
    configMap?: V1ConfigMapProjection;
    /**
     * 
     * @type {V1DownwardAPIProjection}
     * @memberof V1VolumeProjection
     */
    downwardAPI?: V1DownwardAPIProjection;
    /**
     * 
     * @type {V1SecretProjection}
     * @memberof V1VolumeProjection
     */
    secret?: V1SecretProjection;
    /**
     * 
     * @type {V1ServiceAccountTokenProjection}
     * @memberof V1VolumeProjection
     */
    serviceAccountToken?: V1ServiceAccountTokenProjection;
}

export function V1VolumeProjectionFromJSON(json: any): V1VolumeProjection {
    return V1VolumeProjectionFromJSONTyped(json, false);
}

export function V1VolumeProjectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1VolumeProjection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'configMap': !exists(json, 'configMap') ? undefined : V1ConfigMapProjectionFromJSON(json['configMap']),
        'downwardAPI': !exists(json, 'downwardAPI') ? undefined : V1DownwardAPIProjectionFromJSON(json['downwardAPI']),
        'secret': !exists(json, 'secret') ? undefined : V1SecretProjectionFromJSON(json['secret']),
        'serviceAccountToken': !exists(json, 'serviceAccountToken') ? undefined : V1ServiceAccountTokenProjectionFromJSON(json['serviceAccountToken']),
    };
}

export function V1VolumeProjectionToJSON(value?: V1VolumeProjection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configMap': V1ConfigMapProjectionToJSON(value.configMap),
        'downwardAPI': V1DownwardAPIProjectionToJSON(value.downwardAPI),
        'secret': V1SecretProjectionToJSON(value.secret),
        'serviceAccountToken': V1ServiceAccountTokenProjectionToJSON(value.serviceAccountToken),
    };
}

