/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1LabelSelector,
    V1LabelSelectorFromJSON,
    V1LabelSelectorFromJSONTyped,
    V1LabelSelectorToJSON,
} from './V1LabelSelector';

/**
 * 
 * @export
 * @interface V1TopologySpreadConstraint
 */
export interface V1TopologySpreadConstraint {
    /**
     * 
     * @type {V1LabelSelector}
     * @memberof V1TopologySpreadConstraint
     */
    labelSelector?: V1LabelSelector;
    /**
     * MaxSkew describes the degree to which pods may be unevenly distributed.
     * When `whenUnsatisfiable=DoNotSchedule`, it is the maximum permitted difference
     * between the number of matching pods in the target topology and the global minimum.
     * For example, in a 3-zone cluster, MaxSkew is set to 1, and pods with the same
     * labelSelector spread as 1/1/0:
     * +-------+-------+-------+
     *  zone1 | zone2 | zone3 |
     * +-------+-------+-------+
     *    P   |   P   |       |
     * +-------+-------+-------+
     * if MaxSkew is 1, incoming pod can only be scheduled to zone3 to become 1/1/1;
     * scheduling it onto zone1(zone2) would make the ActualSkew(2-0) on zone1(zone2)
     * violate MaxSkew(1).
     * if MaxSkew is 2, incoming pod can be scheduled onto any zone.
     * When `whenUnsatisfiable=ScheduleAnyway`, it is used to give higher precedence
     * to topologies that satisfy it.
     * It's a required field. Default value is 1 and 0 is not allowed.
     * @type {number}
     * @memberof V1TopologySpreadConstraint
     */
    maxSkew?: number;
    /**
     * TopologyKey is the key of node labels. Nodes that have a label with this key
     * and identical values are considered to be in the same topology.
     * We consider each <key, value> as a "bucket", and try to put balanced number
     * of pods into each bucket.
     * It's a required field.
     * @type {string}
     * @memberof V1TopologySpreadConstraint
     */
    topologyKey?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TopologySpreadConstraint
     */
    whenUnsatisfiable?: string;
}

export function V1TopologySpreadConstraintFromJSON(json: any): V1TopologySpreadConstraint {
    return V1TopologySpreadConstraintFromJSONTyped(json, false);
}

export function V1TopologySpreadConstraintFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1TopologySpreadConstraint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labelSelector': !exists(json, 'labelSelector') ? undefined : V1LabelSelectorFromJSON(json['labelSelector']),
        'maxSkew': !exists(json, 'maxSkew') ? undefined : json['maxSkew'],
        'topologyKey': !exists(json, 'topologyKey') ? undefined : json['topologyKey'],
        'whenUnsatisfiable': !exists(json, 'whenUnsatisfiable') ? undefined : json['whenUnsatisfiable'],
    };
}

export function V1TopologySpreadConstraintToJSON(value?: V1TopologySpreadConstraint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labelSelector': V1LabelSelectorToJSON(value.labelSelector),
        'maxSkew': value.maxSkew,
        'topologyKey': value.topologyKey,
        'whenUnsatisfiable': value.whenUnsatisfiable,
    };
}

