/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiJobReprioritizeRequest
 */
export interface ApiJobReprioritizeRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiJobReprioritizeRequest
     */
    jobIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiJobReprioritizeRequest
     */
    jobSetId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiJobReprioritizeRequest
     */
    newPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiJobReprioritizeRequest
     */
    queue?: string;
}

export function ApiJobReprioritizeRequestFromJSON(json: any): ApiJobReprioritizeRequest {
    return ApiJobReprioritizeRequestFromJSONTyped(json, false);
}

export function ApiJobReprioritizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJobReprioritizeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobIds': !exists(json, 'jobIds') ? undefined : json['jobIds'],
        'jobSetId': !exists(json, 'jobSetId') ? undefined : json['jobSetId'],
        'newPriority': !exists(json, 'newPriority') ? undefined : json['newPriority'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
    };
}

export function ApiJobReprioritizeRequestToJSON(value?: ApiJobReprioritizeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobIds': value.jobIds,
        'jobSetId': value.jobSetId,
        'newPriority': value.newPriority,
        'queue': value.queue,
    };
}

