/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1NodeAffinity,
    V1NodeAffinityFromJSON,
    V1NodeAffinityFromJSONTyped,
    V1NodeAffinityToJSON,
} from './V1NodeAffinity';
import {
    V1PodAffinity,
    V1PodAffinityFromJSON,
    V1PodAffinityFromJSONTyped,
    V1PodAffinityToJSON,
} from './V1PodAffinity';
import {
    V1PodAntiAffinity,
    V1PodAntiAffinityFromJSON,
    V1PodAntiAffinityFromJSONTyped,
    V1PodAntiAffinityToJSON,
} from './V1PodAntiAffinity';

/**
 * 
 * @export
 * @interface V1Affinity
 */
export interface V1Affinity {
    /**
     * 
     * @type {V1NodeAffinity}
     * @memberof V1Affinity
     */
    nodeAffinity?: V1NodeAffinity;
    /**
     * 
     * @type {V1PodAffinity}
     * @memberof V1Affinity
     */
    podAffinity?: V1PodAffinity;
    /**
     * 
     * @type {V1PodAntiAffinity}
     * @memberof V1Affinity
     */
    podAntiAffinity?: V1PodAntiAffinity;
}

export function V1AffinityFromJSON(json: any): V1Affinity {
    return V1AffinityFromJSONTyped(json, false);
}

export function V1AffinityFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Affinity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeAffinity': !exists(json, 'nodeAffinity') ? undefined : V1NodeAffinityFromJSON(json['nodeAffinity']),
        'podAffinity': !exists(json, 'podAffinity') ? undefined : V1PodAffinityFromJSON(json['podAffinity']),
        'podAntiAffinity': !exists(json, 'podAntiAffinity') ? undefined : V1PodAntiAffinityFromJSON(json['podAntiAffinity']),
    };
}

export function V1AffinityToJSON(value?: V1Affinity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeAffinity': V1NodeAffinityToJSON(value.nodeAffinity),
        'podAffinity': V1PodAffinityToJSON(value.podAffinity),
        'podAntiAffinity': V1PodAntiAffinityToJSON(value.podAntiAffinity),
    };
}

