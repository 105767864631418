/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An AWS EBS disk must exist before mounting to a container. The disk
 * must also be in the same AWS zone as the kubelet. An AWS EBS disk
 * can only be mounted as read/write once. AWS EBS volumes support
 * ownership management and SELinux relabeling.
 * @export
 * @interface V1AWSElasticBlockStoreVolumeSource
 */
export interface V1AWSElasticBlockStoreVolumeSource {
    /**
     * Filesystem type of the volume that you want to mount.
     * Tip: Ensure that the filesystem type is supported by the host operating system.
     * Examples: "ext4", "xfs", "ntfs". Implicitly inferred to be "ext4" if unspecified.
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#awselasticblockstore
     * TODO: how do we prevent errors in the filesystem from compromising the machine
     * +optional
     * @type {string}
     * @memberof V1AWSElasticBlockStoreVolumeSource
     */
    fsType?: string;
    /**
     * The partition in the volume that you want to mount.
     * If omitted, the default is to mount by volume name.
     * Examples: For volume /dev/sda1, you specify the partition as "1".
     * Similarly, the volume partition for /dev/sda is "0" (or you can leave the property empty).
     * +optional
     * @type {number}
     * @memberof V1AWSElasticBlockStoreVolumeSource
     */
    partition?: number;
    /**
     * Specify "true" to force and set the ReadOnly property in VolumeMounts to "true".
     * If omitted, the default is "false".
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#awselasticblockstore
     * +optional
     * @type {boolean}
     * @memberof V1AWSElasticBlockStoreVolumeSource
     */
    readOnly?: boolean;
    /**
     * Unique ID of the persistent disk resource in AWS (Amazon EBS volume).
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#awselasticblockstore
     * @type {string}
     * @memberof V1AWSElasticBlockStoreVolumeSource
     */
    volumeID?: string;
}

export function V1AWSElasticBlockStoreVolumeSourceFromJSON(json: any): V1AWSElasticBlockStoreVolumeSource {
    return V1AWSElasticBlockStoreVolumeSourceFromJSONTyped(json, false);
}

export function V1AWSElasticBlockStoreVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AWSElasticBlockStoreVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsType': !exists(json, 'fsType') ? undefined : json['fsType'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'volumeID': !exists(json, 'volumeID') ? undefined : json['volumeID'],
    };
}

export function V1AWSElasticBlockStoreVolumeSourceToJSON(value?: V1AWSElasticBlockStoreVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsType': value.fsType,
        'partition': value.partition,
        'readOnly': value.readOnly,
        'volumeID': value.volumeID,
    };
}

