/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1VolumeMount
 */
export interface V1VolumeMount {
    /**
     * Path within the container at which the volume should be mounted.  Must
     * not contain ':'.
     * @type {string}
     * @memberof V1VolumeMount
     */
    mountPath?: string;
    /**
     * 
     * @type {string}
     * @memberof V1VolumeMount
     */
    mountPropagation?: string;
    /**
     * This must match the Name of a Volume.
     * @type {string}
     * @memberof V1VolumeMount
     */
    name?: string;
    /**
     * Mounted read-only if true, read-write otherwise (false or unspecified).
     * Defaults to false.
     * +optional
     * @type {boolean}
     * @memberof V1VolumeMount
     */
    readOnly?: boolean;
    /**
     * Path within the volume from which the container's volume should be mounted.
     * Defaults to "" (volume's root).
     * +optional
     * @type {string}
     * @memberof V1VolumeMount
     */
    subPath?: string;
    /**
     * Expanded path within the volume from which the container's volume should be mounted.
     * Behaves similarly to SubPath but environment variable references $(VAR_NAME) are expanded using the container's environment.
     * Defaults to "" (volume's root).
     * SubPathExpr and SubPath are mutually exclusive.
     * +optional
     * @type {string}
     * @memberof V1VolumeMount
     */
    subPathExpr?: string;
}

export function V1VolumeMountFromJSON(json: any): V1VolumeMount {
    return V1VolumeMountFromJSONTyped(json, false);
}

export function V1VolumeMountFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1VolumeMount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mountPath': !exists(json, 'mountPath') ? undefined : json['mountPath'],
        'mountPropagation': !exists(json, 'mountPropagation') ? undefined : json['mountPropagation'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'subPath': !exists(json, 'subPath') ? undefined : json['subPath'],
        'subPathExpr': !exists(json, 'subPathExpr') ? undefined : json['subPathExpr'],
    };
}

export function V1VolumeMountToJSON(value?: V1VolumeMount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mountPath': value.mountPath,
        'mountPropagation': value.mountPropagation,
        'name': value.name,
        'readOnly': value.readOnly,
        'subPath': value.subPath,
        'subPathExpr': value.subPathExpr,
    };
}

