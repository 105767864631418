/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ObjectMeta,
    V1ObjectMetaFromJSON,
    V1ObjectMetaFromJSONTyped,
    V1ObjectMetaToJSON,
} from './V1ObjectMeta';
import {
    V1ServiceSpec,
    V1ServiceSpecFromJSON,
    V1ServiceSpecFromJSONTyped,
    V1ServiceSpecToJSON,
} from './V1ServiceSpec';
import {
    V1ServiceStatus,
    V1ServiceStatusFromJSON,
    V1ServiceStatusFromJSONTyped,
    V1ServiceStatusToJSON,
} from './V1ServiceStatus';

/**
 * Service is a named abstraction of software service (for example, mysql) consisting of local port
 * (for example 3306) that the proxy listens on, and the selector that determines which pods
 * will answer requests sent through the proxy.
 * @export
 * @interface V1Service
 */
export interface V1Service {
    /**
     * 
     * @type {V1ObjectMeta}
     * @memberof V1Service
     */
    metadata?: V1ObjectMeta;
    /**
     * 
     * @type {V1ServiceSpec}
     * @memberof V1Service
     */
    spec?: V1ServiceSpec;
    /**
     * 
     * @type {V1ServiceStatus}
     * @memberof V1Service
     */
    status?: V1ServiceStatus;
}

export function V1ServiceFromJSON(json: any): V1Service {
    return V1ServiceFromJSONTyped(json, false);
}

export function V1ServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Service {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : V1ObjectMetaFromJSON(json['metadata']),
        'spec': !exists(json, 'spec') ? undefined : V1ServiceSpecFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : V1ServiceStatusFromJSON(json['status']),
    };
}

export function V1ServiceToJSON(value?: V1Service | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': V1ObjectMetaToJSON(value.metadata),
        'spec': V1ServiceSpecToJSON(value.spec),
        'status': V1ServiceStatusToJSON(value.status),
    };
}

