/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookoutDurationStats
 */
export interface LookoutDurationStats {
    /**
     * 
     * @type {string}
     * @memberof LookoutDurationStats
     */
    average?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutDurationStats
     */
    longest?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutDurationStats
     */
    median?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutDurationStats
     */
    q1?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutDurationStats
     */
    q3?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutDurationStats
     */
    shortest?: string;
}

export function LookoutDurationStatsFromJSON(json: any): LookoutDurationStats {
    return LookoutDurationStatsFromJSONTyped(json, false);
}

export function LookoutDurationStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutDurationStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'average': !exists(json, 'average') ? undefined : json['average'],
        'longest': !exists(json, 'longest') ? undefined : json['longest'],
        'median': !exists(json, 'median') ? undefined : json['median'],
        'q1': !exists(json, 'q1') ? undefined : json['q1'],
        'q3': !exists(json, 'q3') ? undefined : json['q3'],
        'shortest': !exists(json, 'shortest') ? undefined : json['shortest'],
    };
}

export function LookoutDurationStatsToJSON(value?: LookoutDurationStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'average': value.average,
        'longest': value.longest,
        'median': value.median,
        'q1': value.q1,
        'q3': value.q3,
        'shortest': value.shortest,
    };
}

