/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A GCE PD must exist before mounting to a container. The disk must
 * also be in the same GCE project and zone as the kubelet. A GCE PD
 * can only be mounted as read/write once or read-only many times. GCE
 * PDs support ownership management and SELinux relabeling.
 * @export
 * @interface V1GCEPersistentDiskVolumeSource
 */
export interface V1GCEPersistentDiskVolumeSource {
    /**
     * Filesystem type of the volume that you want to mount.
     * Tip: Ensure that the filesystem type is supported by the host operating system.
     * Examples: "ext4", "xfs", "ntfs". Implicitly inferred to be "ext4" if unspecified.
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk
     * TODO: how do we prevent errors in the filesystem from compromising the machine
     * +optional
     * @type {string}
     * @memberof V1GCEPersistentDiskVolumeSource
     */
    fsType?: string;
    /**
     * The partition in the volume that you want to mount.
     * If omitted, the default is to mount by volume name.
     * Examples: For volume /dev/sda1, you specify the partition as "1".
     * Similarly, the volume partition for /dev/sda is "0" (or you can leave the property empty).
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk
     * +optional
     * @type {number}
     * @memberof V1GCEPersistentDiskVolumeSource
     */
    partition?: number;
    /**
     * Unique name of the PD resource in GCE. Used to identify the disk in GCE.
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk
     * @type {string}
     * @memberof V1GCEPersistentDiskVolumeSource
     */
    pdName?: string;
    /**
     * ReadOnly here will force the ReadOnly setting in VolumeMounts.
     * Defaults to false.
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk
     * +optional
     * @type {boolean}
     * @memberof V1GCEPersistentDiskVolumeSource
     */
    readOnly?: boolean;
}

export function V1GCEPersistentDiskVolumeSourceFromJSON(json: any): V1GCEPersistentDiskVolumeSource {
    return V1GCEPersistentDiskVolumeSourceFromJSONTyped(json, false);
}

export function V1GCEPersistentDiskVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GCEPersistentDiskVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsType': !exists(json, 'fsType') ? undefined : json['fsType'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'pdName': !exists(json, 'pdName') ? undefined : json['pdName'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
    };
}

export function V1GCEPersistentDiskVolumeSourceToJSON(value?: V1GCEPersistentDiskVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsType': value.fsType,
        'partition': value.partition,
        'pdName': value.pdName,
        'readOnly': value.readOnly,
    };
}

