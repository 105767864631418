/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * +structType=atomic
 * @export
 * @interface V1ObjectFieldSelector
 */
export interface V1ObjectFieldSelector {
    /**
     * Version of the schema the FieldPath is written in terms of, defaults to "v1".
     * +optional
     * @type {string}
     * @memberof V1ObjectFieldSelector
     */
    apiVersion?: string;
    /**
     * Path of the field to select in the specified API version.
     * @type {string}
     * @memberof V1ObjectFieldSelector
     */
    fieldPath?: string;
}

export function V1ObjectFieldSelectorFromJSON(json: any): V1ObjectFieldSelector {
    return V1ObjectFieldSelectorFromJSONTyped(json, false);
}

export function V1ObjectFieldSelectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ObjectFieldSelector {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': !exists(json, 'apiVersion') ? undefined : json['apiVersion'],
        'fieldPath': !exists(json, 'fieldPath') ? undefined : json['fieldPath'],
    };
}

export function V1ObjectFieldSelectorToJSON(value?: V1ObjectFieldSelector | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': value.apiVersion,
        'fieldPath': value.fieldPath,
    };
}

