/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiQueue,
    ApiQueueFromJSON,
    ApiQueueFromJSONTyped,
    ApiQueueToJSON,
} from './ApiQueue';

/**
 * 
 * @export
 * @interface ApiQueueList
 */
export interface ApiQueueList {
    /**
     * 
     * @type {Array<ApiQueue>}
     * @memberof ApiQueueList
     */
    queues?: Array<ApiQueue>;
}

export function ApiQueueListFromJSON(json: any): ApiQueueList {
    return ApiQueueListFromJSONTyped(json, false);
}

export function ApiQueueListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiQueueList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queues': !exists(json, 'queues') ? undefined : ((json['queues'] as Array<any>).map(ApiQueueFromJSON)),
    };
}

export function ApiQueueListToJSON(value?: ApiQueueList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queues': value.queues === undefined ? undefined : ((value.queues as Array<any>).map(ApiQueueToJSON)),
    };
}

