/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiQueue,
    ApiQueueFromJSON,
    ApiQueueFromJSONTyped,
    ApiQueueToJSON,
} from './ApiQueue';

/**
 * 
 * @export
 * @interface ApiQueueCreateResponse
 */
export interface ApiQueueCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiQueueCreateResponse
     */
    error?: string;
    /**
     * 
     * @type {ApiQueue}
     * @memberof ApiQueueCreateResponse
     */
    queue?: ApiQueue;
}

export function ApiQueueCreateResponseFromJSON(json: any): ApiQueueCreateResponse {
    return ApiQueueCreateResponseFromJSONTyped(json, false);
}

export function ApiQueueCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiQueueCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'queue': !exists(json, 'queue') ? undefined : ApiQueueFromJSON(json['queue']),
    };
}

export function ApiQueueCreateResponseToJSON(value?: ApiQueueCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'queue': ApiQueueToJSON(value.queue),
    };
}

