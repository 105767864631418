/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * PodReadinessGate contains the reference to a pod condition
 * @export
 * @interface V1PodReadinessGate
 */
export interface V1PodReadinessGate {
    /**
     * PodConditionType is a valid value for PodCondition.Type
     * @type {string}
     * @memberof V1PodReadinessGate
     */
    conditionType?: string;
}

export function V1PodReadinessGateFromJSON(json: any): V1PodReadinessGate {
    return V1PodReadinessGateFromJSONTyped(json, false);
}

export function V1PodReadinessGateFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PodReadinessGate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditionType': !exists(json, 'conditionType') ? undefined : json['conditionType'],
    };
}

export function V1PodReadinessGateToJSON(value?: V1PodReadinessGate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditionType': value.conditionType,
    };
}

