/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1AzureFileVolumeSource
 */
export interface V1AzureFileVolumeSource {
    /**
     * Defaults to false (read/write). ReadOnly here will force
     * the ReadOnly setting in VolumeMounts.
     * +optional
     * @type {boolean}
     * @memberof V1AzureFileVolumeSource
     */
    readOnly?: boolean;
    /**
     * the name of secret that contains Azure Storage Account Name and Key
     * @type {string}
     * @memberof V1AzureFileVolumeSource
     */
    secretName?: string;
    /**
     * Share Name
     * @type {string}
     * @memberof V1AzureFileVolumeSource
     */
    shareName?: string;
}

export function V1AzureFileVolumeSourceFromJSON(json: any): V1AzureFileVolumeSource {
    return V1AzureFileVolumeSourceFromJSONTyped(json, false);
}

export function V1AzureFileVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AzureFileVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'secretName': !exists(json, 'secretName') ? undefined : json['secretName'],
        'shareName': !exists(json, 'shareName') ? undefined : json['shareName'],
    };
}

export function V1AzureFileVolumeSourceToJSON(value?: V1AzureFileVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readOnly': value.readOnly,
        'secretName': value.secretName,
        'shareName': value.shareName,
    };
}

