/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Only one profile source may be set.
 * +union
 * @export
 * @interface V1SeccompProfile
 */
export interface V1SeccompProfile {
    /**
     * localhostProfile indicates a profile defined in a file on the node should be used.
     * The profile must be preconfigured on the node to work.
     * Must be a descending path, relative to the kubelet's configured seccomp profile location.
     * Must only be set if type is "Localhost".
     * +optional
     * @type {string}
     * @memberof V1SeccompProfile
     */
    localhostProfile?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SeccompProfile
     */
    type?: string;
}

export function V1SeccompProfileFromJSON(json: any): V1SeccompProfile {
    return V1SeccompProfileFromJSONTyped(json, false);
}

export function V1SeccompProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SeccompProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localhostProfile': !exists(json, 'localhostProfile') ? undefined : json['localhostProfile'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function V1SeccompProfileToJSON(value?: V1SeccompProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localhostProfile': value.localhostProfile,
        'type': value.type,
    };
}

