/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiJobSetRequest,
    ApiJobSetRequestFromJSON,
    ApiJobSetRequestToJSON,
    RuntimeError,
    RuntimeErrorFromJSON,
    RuntimeErrorToJSON,
} from '../models';

export interface GetJobSetEventsRequest {
    queue: string;
    id: string;
    body: ApiJobSetRequest;
}

/**
 * 
 */
export class EventApi extends runtime.BaseAPI {

    /**
     */
    async getJobSetEventsRaw(requestParameters: GetJobSetEventsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.queue === null || requestParameters.queue === undefined) {
            throw new runtime.RequiredError('queue','Required parameter requestParameters.queue was null or undefined when calling getJobSetEvents.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJobSetEvents.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getJobSetEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/job-set/{queue}/{id}`.replace(`{${"queue"}}`, encodeURIComponent(String(requestParameters.queue))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiJobSetRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getJobSetEvents(requestParameters: GetJobSetEventsRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getJobSetEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
