/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/binoculars/binoculars.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinocularsLogLine,
    BinocularsLogLineFromJSON,
    BinocularsLogLineFromJSONTyped,
    BinocularsLogLineToJSON,
} from './BinocularsLogLine';

/**
 * 
 * @export
 * @interface BinocularsLogResponse
 */
export interface BinocularsLogResponse {
    /**
     * 
     * @type {Array<BinocularsLogLine>}
     * @memberof BinocularsLogResponse
     */
    log?: Array<BinocularsLogLine>;
}

export function BinocularsLogResponseFromJSON(json: any): BinocularsLogResponse {
    return BinocularsLogResponseFromJSONTyped(json, false);
}

export function BinocularsLogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinocularsLogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'log': !exists(json, 'log') ? undefined : ((json['log'] as Array<any>).map(BinocularsLogLineFromJSON)),
    };
}

export function BinocularsLogResponseToJSON(value?: BinocularsLogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'log': value.log === undefined ? undefined : ((value.log as Array<any>).map(BinocularsLogLineToJSON)),
    };
}

