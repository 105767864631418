/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This volume finds the bound PV and mounts that volume for the pod. A
 * PersistentVolumeClaimVolumeSource is, essentially, a wrapper around another
 * type of volume that is owned by someone else (the system).
 * @export
 * @interface V1PersistentVolumeClaimVolumeSource
 */
export interface V1PersistentVolumeClaimVolumeSource {
    /**
     * ClaimName is the name of a PersistentVolumeClaim in the same namespace as the pod using this volume.
     * More info: https://kubernetes.io/docs/concepts/storage/persistent-volumes#persistentvolumeclaims
     * @type {string}
     * @memberof V1PersistentVolumeClaimVolumeSource
     */
    claimName?: string;
    /**
     * Will force the ReadOnly setting in VolumeMounts.
     * Default false.
     * +optional
     * @type {boolean}
     * @memberof V1PersistentVolumeClaimVolumeSource
     */
    readOnly?: boolean;
}

export function V1PersistentVolumeClaimVolumeSourceFromJSON(json: any): V1PersistentVolumeClaimVolumeSource {
    return V1PersistentVolumeClaimVolumeSourceFromJSONTyped(json, false);
}

export function V1PersistentVolumeClaimVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PersistentVolumeClaimVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'claimName': !exists(json, 'claimName') ? undefined : json['claimName'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
    };
}

export function V1PersistentVolumeClaimVolumeSourceToJSON(value?: V1PersistentVolumeClaimVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'claimName': value.claimName,
        'readOnly': value.readOnly,
    };
}

