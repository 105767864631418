/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * +protobuf=true
 * +protobuf.options.(gogoproto.goproto_stringer)=false
 * +k8s:openapi-gen=true
 * @export
 * @interface IntstrIntOrString
 */
export interface IntstrIntOrString {
    /**
     * 
     * @type {number}
     * @memberof IntstrIntOrString
     */
    intVal?: number;
    /**
     * 
     * @type {string}
     * @memberof IntstrIntOrString
     */
    strVal?: string;
    /**
     * 
     * @type {number}
     * @memberof IntstrIntOrString
     */
    type?: number;
}

export function IntstrIntOrStringFromJSON(json: any): IntstrIntOrString {
    return IntstrIntOrStringFromJSONTyped(json, false);
}

export function IntstrIntOrStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntstrIntOrString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intVal': !exists(json, 'IntVal') ? undefined : json['IntVal'],
        'strVal': !exists(json, 'StrVal') ? undefined : json['StrVal'],
        'type': !exists(json, 'Type') ? undefined : json['Type'],
    };
}

export function IntstrIntOrStringToJSON(value?: IntstrIntOrString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IntVal': value.intVal,
        'StrVal': value.strVal,
        'Type': value.type,
    };
}

