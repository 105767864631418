/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * OwnerReference contains enough information to let you identify an owning
 * object. An owning object must be in the same namespace as the dependent, or
 * be cluster-scoped, so there is no namespace field.
 * +structType=atomic
 * @export
 * @interface V1OwnerReference
 */
export interface V1OwnerReference {
    /**
     * API version of the referent.
     * @type {string}
     * @memberof V1OwnerReference
     */
    apiVersion?: string;
    /**
     * If true, AND if the owner has the "foregroundDeletion" finalizer, then
     * the owner cannot be deleted from the key-value store until this
     * reference is removed.
     * Defaults to false.
     * To set this field, a user needs "delete" permission of the owner,
     * otherwise 422 (Unprocessable Entity) will be returned.
     * +optional
     * @type {boolean}
     * @memberof V1OwnerReference
     */
    blockOwnerDeletion?: boolean;
    /**
     * If true, this reference points to the managing controller.
     * +optional
     * @type {boolean}
     * @memberof V1OwnerReference
     */
    controller?: boolean;
    /**
     * Kind of the referent.
     * More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof V1OwnerReference
     */
    kind?: string;
    /**
     * Name of the referent.
     * More info: http://kubernetes.io/docs/user-guide/identifiers#names
     * @type {string}
     * @memberof V1OwnerReference
     */
    name?: string;
    /**
     * UID is a type that holds unique ID values, including UUIDs.  Because we
     * don't ONLY use UUIDs, this is an alias to string.  Being a type captures
     * intent and helps make sure that UIDs and names do not get conflated.
     * @type {string}
     * @memberof V1OwnerReference
     */
    uid?: string;
}

export function V1OwnerReferenceFromJSON(json: any): V1OwnerReference {
    return V1OwnerReferenceFromJSONTyped(json, false);
}

export function V1OwnerReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1OwnerReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': !exists(json, 'apiVersion') ? undefined : json['apiVersion'],
        'blockOwnerDeletion': !exists(json, 'blockOwnerDeletion') ? undefined : json['blockOwnerDeletion'],
        'controller': !exists(json, 'controller') ? undefined : json['controller'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function V1OwnerReferenceToJSON(value?: V1OwnerReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': value.apiVersion,
        'blockOwnerDeletion': value.blockOwnerDeletion,
        'controller': value.controller,
        'kind': value.kind,
        'name': value.name,
        'uid': value.uid,
    };
}

