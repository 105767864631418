/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiJobCancelRequest
 */
export interface ApiJobCancelRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiJobCancelRequest
     */
    jobId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiJobCancelRequest
     */
    jobIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiJobCancelRequest
     */
    jobSetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJobCancelRequest
     */
    queue?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJobCancelRequest
     */
    reason?: string;
}

export function ApiJobCancelRequestFromJSON(json: any): ApiJobCancelRequest {
    return ApiJobCancelRequestFromJSONTyped(json, false);
}

export function ApiJobCancelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJobCancelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'jobIds': !exists(json, 'jobIds') ? undefined : json['jobIds'],
        'jobSetId': !exists(json, 'jobSetId') ? undefined : json['jobSetId'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function ApiJobCancelRequestToJSON(value?: ApiJobCancelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobId': value.jobId,
        'jobIds': value.jobIds,
        'jobSetId': value.jobSetId,
        'queue': value.queue,
        'reason': value.reason,
    };
}

