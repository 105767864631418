/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1KeyToPath,
    V1KeyToPathFromJSON,
    V1KeyToPathFromJSONTyped,
    V1KeyToPathToJSON,
} from './V1KeyToPath';

/**
 * The contents of the target Secret's Data field will be presented in a volume
 * as files using the keys in the Data field as the file names.
 * Secret volumes support ownership management and SELinux relabeling.
 * @export
 * @interface V1SecretVolumeSource
 */
export interface V1SecretVolumeSource {
    /**
     * Optional: mode bits used to set permissions on created files by default.
     * Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511.
     * YAML accepts both octal and decimal values, JSON requires decimal values
     * for mode bits. Defaults to 0644.
     * Directories within the path are not affected by this setting.
     * This might be in conflict with other options that affect the file
     * mode, like fsGroup, and the result can be other mode bits set.
     * +optional
     * @type {number}
     * @memberof V1SecretVolumeSource
     */
    defaultMode?: number;
    /**
     * If unspecified, each key-value pair in the Data field of the referenced
     * Secret will be projected into the volume as a file whose name is the
     * key and content is the value. If specified, the listed keys will be
     * projected into the specified paths, and unlisted keys will not be
     * present. If a key is specified which is not present in the Secret,
     * the volume setup will error unless it is marked optional. Paths must be
     * relative and may not contain the '..' path or start with '..'.
     * +optional
     * @type {Array<V1KeyToPath>}
     * @memberof V1SecretVolumeSource
     */
    items?: Array<V1KeyToPath>;
    /**
     * Specify whether the Secret or its keys must be defined
     * +optional
     * @type {boolean}
     * @memberof V1SecretVolumeSource
     */
    optional?: boolean;
    /**
     * Name of the secret in the pod's namespace to use.
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#secret
     * +optional
     * @type {string}
     * @memberof V1SecretVolumeSource
     */
    secretName?: string;
}

export function V1SecretVolumeSourceFromJSON(json: any): V1SecretVolumeSource {
    return V1SecretVolumeSourceFromJSONTyped(json, false);
}

export function V1SecretVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SecretVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultMode': !exists(json, 'defaultMode') ? undefined : json['defaultMode'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(V1KeyToPathFromJSON)),
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
        'secretName': !exists(json, 'secretName') ? undefined : json['secretName'],
    };
}

export function V1SecretVolumeSourceToJSON(value?: V1SecretVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultMode': value.defaultMode,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(V1KeyToPathToJSON)),
        'optional': value.optional,
        'secretName': value.secretName,
    };
}

