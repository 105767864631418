/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1SELinuxOptions,
    V1SELinuxOptionsFromJSON,
    V1SELinuxOptionsFromJSONTyped,
    V1SELinuxOptionsToJSON,
} from './V1SELinuxOptions';
import {
    V1SeccompProfile,
    V1SeccompProfileFromJSON,
    V1SeccompProfileFromJSONTyped,
    V1SeccompProfileToJSON,
} from './V1SeccompProfile';
import {
    V1Sysctl,
    V1SysctlFromJSON,
    V1SysctlFromJSONTyped,
    V1SysctlToJSON,
} from './V1Sysctl';
import {
    V1WindowsSecurityContextOptions,
    V1WindowsSecurityContextOptionsFromJSON,
    V1WindowsSecurityContextOptionsFromJSONTyped,
    V1WindowsSecurityContextOptionsToJSON,
} from './V1WindowsSecurityContextOptions';

/**
 * Some fields are also present in container.securityContext.  Field values of
 * container.securityContext take precedence over field values of PodSecurityContext.
 * @export
 * @interface V1PodSecurityContext
 */
export interface V1PodSecurityContext {
    /**
     * A special supplemental group that applies to all containers in a pod.
     * Some volume types allow the Kubelet to change the ownership of that volume
     * to be owned by the pod:
     * 
     * 1. The owning GID will be the FSGroup
     * 2. The setgid bit is set (new files created in the volume will be owned by FSGroup)
     * 3. The permission bits are OR'd with rw-rw----
     * 
     * If unset, the Kubelet will not modify the ownership and permissions of any volume.
     * +optional
     * @type {number}
     * @memberof V1PodSecurityContext
     */
    fsGroup?: number;
    /**
     * PodFSGroupChangePolicy holds policies that will be used for applying fsGroup to a volume
     * when volume is mounted.
     * @type {string}
     * @memberof V1PodSecurityContext
     */
    fsGroupChangePolicy?: string;
    /**
     * The GID to run the entrypoint of the container process.
     * Uses runtime default if unset.
     * May also be set in SecurityContext.  If set in both SecurityContext and
     * PodSecurityContext, the value specified in SecurityContext takes precedence
     * for that container.
     * +optional
     * @type {number}
     * @memberof V1PodSecurityContext
     */
    runAsGroup?: number;
    /**
     * Indicates that the container must run as a non-root user.
     * If true, the Kubelet will validate the image at runtime to ensure that it
     * does not run as UID 0 (root) and fail to start the container if it does.
     * If unset or false, no such validation will be performed.
     * May also be set in SecurityContext.  If set in both SecurityContext and
     * PodSecurityContext, the value specified in SecurityContext takes precedence.
     * +optional
     * @type {boolean}
     * @memberof V1PodSecurityContext
     */
    runAsNonRoot?: boolean;
    /**
     * The UID to run the entrypoint of the container process.
     * Defaults to user specified in image metadata if unspecified.
     * May also be set in SecurityContext.  If set in both SecurityContext and
     * PodSecurityContext, the value specified in SecurityContext takes precedence
     * for that container.
     * +optional
     * @type {number}
     * @memberof V1PodSecurityContext
     */
    runAsUser?: number;
    /**
     * 
     * @type {V1SELinuxOptions}
     * @memberof V1PodSecurityContext
     */
    seLinuxOptions?: V1SELinuxOptions;
    /**
     * 
     * @type {V1SeccompProfile}
     * @memberof V1PodSecurityContext
     */
    seccompProfile?: V1SeccompProfile;
    /**
     * A list of groups applied to the first process run in each container, in addition
     * to the container's primary GID.  If unspecified, no groups will be added to
     * any container.
     * +optional
     * @type {Array<number>}
     * @memberof V1PodSecurityContext
     */
    supplementalGroups?: Array<number>;
    /**
     * Sysctls hold a list of namespaced sysctls used for the pod. Pods with unsupported
     * sysctls (by the container runtime) might fail to launch.
     * +optional
     * @type {Array<V1Sysctl>}
     * @memberof V1PodSecurityContext
     */
    sysctls?: Array<V1Sysctl>;
    /**
     * 
     * @type {V1WindowsSecurityContextOptions}
     * @memberof V1PodSecurityContext
     */
    windowsOptions?: V1WindowsSecurityContextOptions;
}

export function V1PodSecurityContextFromJSON(json: any): V1PodSecurityContext {
    return V1PodSecurityContextFromJSONTyped(json, false);
}

export function V1PodSecurityContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PodSecurityContext {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsGroup': !exists(json, 'fsGroup') ? undefined : json['fsGroup'],
        'fsGroupChangePolicy': !exists(json, 'fsGroupChangePolicy') ? undefined : json['fsGroupChangePolicy'],
        'runAsGroup': !exists(json, 'runAsGroup') ? undefined : json['runAsGroup'],
        'runAsNonRoot': !exists(json, 'runAsNonRoot') ? undefined : json['runAsNonRoot'],
        'runAsUser': !exists(json, 'runAsUser') ? undefined : json['runAsUser'],
        'seLinuxOptions': !exists(json, 'seLinuxOptions') ? undefined : V1SELinuxOptionsFromJSON(json['seLinuxOptions']),
        'seccompProfile': !exists(json, 'seccompProfile') ? undefined : V1SeccompProfileFromJSON(json['seccompProfile']),
        'supplementalGroups': !exists(json, 'supplementalGroups') ? undefined : json['supplementalGroups'],
        'sysctls': !exists(json, 'sysctls') ? undefined : ((json['sysctls'] as Array<any>).map(V1SysctlFromJSON)),
        'windowsOptions': !exists(json, 'windowsOptions') ? undefined : V1WindowsSecurityContextOptionsFromJSON(json['windowsOptions']),
    };
}

export function V1PodSecurityContextToJSON(value?: V1PodSecurityContext | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsGroup': value.fsGroup,
        'fsGroupChangePolicy': value.fsGroupChangePolicy,
        'runAsGroup': value.runAsGroup,
        'runAsNonRoot': value.runAsNonRoot,
        'runAsUser': value.runAsUser,
        'seLinuxOptions': V1SELinuxOptionsToJSON(value.seLinuxOptions),
        'seccompProfile': V1SeccompProfileToJSON(value.seccompProfile),
        'supplementalGroups': value.supplementalGroups,
        'sysctls': value.sysctls === undefined ? undefined : ((value.sysctls as Array<any>).map(V1SysctlToJSON)),
        'windowsOptions': V1WindowsSecurityContextOptionsToJSON(value.windowsOptions),
    };
}

