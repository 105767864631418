/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ManagedFieldsEntry,
    V1ManagedFieldsEntryFromJSON,
    V1ManagedFieldsEntryFromJSONTyped,
    V1ManagedFieldsEntryToJSON,
} from './V1ManagedFieldsEntry';
import {
    V1OwnerReference,
    V1OwnerReferenceFromJSON,
    V1OwnerReferenceFromJSONTyped,
    V1OwnerReferenceToJSON,
} from './V1OwnerReference';
import {
    V1PersistentVolumeClaimSpec,
    V1PersistentVolumeClaimSpecFromJSON,
    V1PersistentVolumeClaimSpecFromJSONTyped,
    V1PersistentVolumeClaimSpecToJSON,
} from './V1PersistentVolumeClaimSpec';

/**
 * PersistentVolumeClaimTemplate is used to produce
 * PersistentVolumeClaim objects as part of an EphemeralVolumeSource.
 * @export
 * @interface V1PersistentVolumeClaimTemplate
 */
export interface V1PersistentVolumeClaimTemplate {
    /**
     * Annotations is an unstructured key value map stored with a resource that may be
     * set by external tools to store and retrieve arbitrary metadata. They are not
     * queryable and should be preserved when modifying objects.
     * More info: http://kubernetes.io/docs/user-guide/annotations
     * +optional
     * @type {{ [key: string]: string; }}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    annotations?: { [key: string]: string; };
    /**
     * The name of the cluster which the object belongs to.
     * This is used to distinguish resources with same name and namespace in different clusters.
     * This field is not set anywhere right now and apiserver is going to ignore it if set in create or update request.
     * +optional
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    clusterName?: string;
    /**
     * Programs using times should typically store and pass them as values,
     * not pointers. That is, time variables and struct fields should be of
     * type time.Time, not *time.Time.
     * 
     * A Time value can be used by multiple goroutines simultaneously except
     * that the methods GobDecode, UnmarshalBinary, UnmarshalJSON and
     * UnmarshalText are not concurrency-safe.
     * 
     * Time instants can be compared using the Before, After, and Equal methods.
     * The Sub method subtracts two instants, producing a Duration.
     * The Add method adds a Time and a Duration, producing a Time.
     * 
     * The zero value of type Time is January 1, year 1, 00:00:00.000000000 UTC.
     * As this time is unlikely to come up in practice, the IsZero method gives
     * a simple way of detecting a time that has not been initialized explicitly.
     * 
     * Each Time has associated with it a Location, consulted when computing the
     * presentation form of the time, such as in the Format, Hour, and Year methods.
     * The methods Local, UTC, and In return a Time with a specific location.
     * Changing the location in this way changes only the presentation; it does not
     * change the instant in time being denoted and therefore does not affect the
     * computations described in earlier paragraphs.
     * 
     * Representations of a Time value saved by the GobEncode, MarshalBinary,
     * MarshalJSON, and MarshalText methods store the Time.Location's offset, but not
     * the location name. They therefore lose information about Daylight Saving Time.
     * 
     * In addition to the required “wall clock” reading, a Time may contain an optional
     * reading of the current process's monotonic clock, to provide additional precision
     * for comparison or subtraction.
     * See the “Monotonic Clocks” section in the package documentation for details.
     * 
     * Note that the Go == operator compares not just the time instant but also the
     * Location and the monotonic clock reading. Therefore, Time values should not
     * be used as map or database keys without first guaranteeing that the
     * identical Location has been set for all values, which can be achieved
     * through use of the UTC or Local method, and that the monotonic clock reading
     * has been stripped by setting t = t.Round(0). In general, prefer t.Equal(u)
     * to t == u, since t.Equal uses the most accurate comparison available and
     * correctly handles the case when only one of its arguments has a monotonic
     * clock reading.
     * @type {Date}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    creationTimestamp?: Date;
    /**
     * Number of seconds allowed for this object to gracefully terminate before
     * it will be removed from the system. Only set when deletionTimestamp is also set.
     * May only be shortened.
     * Read-only.
     * +optional
     * @type {number}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    deletionGracePeriodSeconds?: number;
    /**
     * Programs using times should typically store and pass them as values,
     * not pointers. That is, time variables and struct fields should be of
     * type time.Time, not *time.Time.
     * 
     * A Time value can be used by multiple goroutines simultaneously except
     * that the methods GobDecode, UnmarshalBinary, UnmarshalJSON and
     * UnmarshalText are not concurrency-safe.
     * 
     * Time instants can be compared using the Before, After, and Equal methods.
     * The Sub method subtracts two instants, producing a Duration.
     * The Add method adds a Time and a Duration, producing a Time.
     * 
     * The zero value of type Time is January 1, year 1, 00:00:00.000000000 UTC.
     * As this time is unlikely to come up in practice, the IsZero method gives
     * a simple way of detecting a time that has not been initialized explicitly.
     * 
     * Each Time has associated with it a Location, consulted when computing the
     * presentation form of the time, such as in the Format, Hour, and Year methods.
     * The methods Local, UTC, and In return a Time with a specific location.
     * Changing the location in this way changes only the presentation; it does not
     * change the instant in time being denoted and therefore does not affect the
     * computations described in earlier paragraphs.
     * 
     * Representations of a Time value saved by the GobEncode, MarshalBinary,
     * MarshalJSON, and MarshalText methods store the Time.Location's offset, but not
     * the location name. They therefore lose information about Daylight Saving Time.
     * 
     * In addition to the required “wall clock” reading, a Time may contain an optional
     * reading of the current process's monotonic clock, to provide additional precision
     * for comparison or subtraction.
     * See the “Monotonic Clocks” section in the package documentation for details.
     * 
     * Note that the Go == operator compares not just the time instant but also the
     * Location and the monotonic clock reading. Therefore, Time values should not
     * be used as map or database keys without first guaranteeing that the
     * identical Location has been set for all values, which can be achieved
     * through use of the UTC or Local method, and that the monotonic clock reading
     * has been stripped by setting t = t.Round(0). In general, prefer t.Equal(u)
     * to t == u, since t.Equal uses the most accurate comparison available and
     * correctly handles the case when only one of its arguments has a monotonic
     * clock reading.
     * @type {Date}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    deletionTimestamp?: Date;
    /**
     * Must be empty before the object is deleted from the registry. Each entry
     * is an identifier for the responsible component that will remove the entry
     * from the list. If the deletionTimestamp of the object is non-nil, entries
     * in this list can only be removed.
     * Finalizers may be processed and removed in any order.  Order is NOT enforced
     * because it introduces significant risk of stuck finalizers.
     * finalizers is a shared field, any actor with permission can reorder it.
     * If the finalizer list is processed in order, then this can lead to a situation
     * in which the component responsible for the first finalizer in the list is
     * waiting for a signal (field value, external system, or other) produced by a
     * component responsible for a finalizer later in the list, resulting in a deadlock.
     * Without enforced ordering finalizers are free to order amongst themselves and
     * are not vulnerable to ordering changes in the list.
     * +optional
     * +patchStrategy=merge
     * @type {Array<string>}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    finalizers?: Array<string>;
    /**
     * GenerateName is an optional prefix, used by the server, to generate a unique
     * name ONLY IF the Name field has not been provided.
     * If this field is used, the name returned to the client will be different
     * than the name passed. This value will also be combined with a unique suffix.
     * The provided value has the same validation rules as the Name field,
     * and may be truncated by the length of the suffix required to make the value
     * unique on the server.
     * 
     * If this field is specified and the generated name exists, the server will
     * NOT return a 409 - instead, it will either return 201 Created or 500 with Reason
     * ServerTimeout indicating a unique name could not be found in the time allotted, and the client
     * should retry (optionally after the time indicated in the Retry-After header).
     * 
     * Applied only if Name is not specified.
     * More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency
     * +optional
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    generateName?: string;
    /**
     * A sequence number representing a specific generation of the desired state.
     * Populated by the system. Read-only.
     * +optional
     * @type {number}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    generation?: number;
    /**
     * Map of string keys and values that can be used to organize and categorize
     * (scope and select) objects. May match selectors of replication controllers
     * and services.
     * More info: http://kubernetes.io/docs/user-guide/labels
     * +optional
     * @type {{ [key: string]: string; }}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    labels?: { [key: string]: string; };
    /**
     * ManagedFields maps workflow-id and version to the set of fields
     * that are managed by that workflow. This is mostly for internal
     * housekeeping, and users typically shouldn't need to set or
     * understand this field. A workflow can be the user's name, a
     * controller's name, or the name of a specific apply path like
     * "ci-cd". The set of fields is always in the version that the
     * workflow used when modifying the object.
     * 
     * +optional
     * @type {Array<V1ManagedFieldsEntry>}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    managedFields?: Array<V1ManagedFieldsEntry>;
    /**
     * Name must be unique within a namespace. Is required when creating resources, although
     * some resources may allow a client to request the generation of an appropriate name
     * automatically. Name is primarily intended for creation idempotence and configuration
     * definition.
     * Cannot be updated.
     * More info: http://kubernetes.io/docs/user-guide/identifiers#names
     * +optional
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    name?: string;
    /**
     * Namespace defines the space within which each name must be unique. An empty namespace is
     * equivalent to the "default" namespace, but "default" is the canonical representation.
     * Not all objects are required to be scoped to a namespace - the value of this field for
     * those objects will be empty.
     * 
     * Must be a DNS_LABEL.
     * Cannot be updated.
     * More info: http://kubernetes.io/docs/user-guide/namespaces
     * +optional
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    namespace?: string;
    /**
     * List of objects depended by this object. If ALL objects in the list have
     * been deleted, this object will be garbage collected. If this object is managed by a controller,
     * then an entry in this list will point to this controller, with the controller field set to true.
     * There cannot be more than one managing controller.
     * +optional
     * +patchMergeKey=uid
     * +patchStrategy=merge
     * @type {Array<V1OwnerReference>}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    ownerReferences?: Array<V1OwnerReference>;
    /**
     * An opaque value that represents the internal version of this object that can
     * be used by clients to determine when objects have changed. May be used for optimistic
     * concurrency, change detection, and the watch operation on a resource or set of resources.
     * Clients must treat these values as opaque and passed unmodified back to the server.
     * They may only be valid for a particular resource or set of resources.
     * 
     * Populated by the system.
     * Read-only.
     * Value must be treated as opaque by clients and .
     * More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * +optional
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    resourceVersion?: string;
    /**
     * SelfLink is a URL representing this object.
     * Populated by the system.
     * Read-only.
     * 
     * DEPRECATED
     * Kubernetes will stop propagating this field in 1.20 release and the field is planned
     * to be removed in 1.21 release.
     * +optional
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    selfLink?: string;
    /**
     * 
     * @type {V1PersistentVolumeClaimSpec}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    spec?: V1PersistentVolumeClaimSpec;
    /**
     * UID is a type that holds unique ID values, including UUIDs.  Because we
     * don't ONLY use UUIDs, this is an alias to string.  Being a type captures
     * intent and helps make sure that UIDs and names do not get conflated.
     * @type {string}
     * @memberof V1PersistentVolumeClaimTemplate
     */
    uid?: string;
}

export function V1PersistentVolumeClaimTemplateFromJSON(json: any): V1PersistentVolumeClaimTemplate {
    return V1PersistentVolumeClaimTemplateFromJSONTyped(json, false);
}

export function V1PersistentVolumeClaimTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PersistentVolumeClaimTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'clusterName': !exists(json, 'clusterName') ? undefined : json['clusterName'],
        'creationTimestamp': !exists(json, 'creationTimestamp') ? undefined : (new Date(json['creationTimestamp'])),
        'deletionGracePeriodSeconds': !exists(json, 'deletionGracePeriodSeconds') ? undefined : json['deletionGracePeriodSeconds'],
        'deletionTimestamp': !exists(json, 'deletionTimestamp') ? undefined : (new Date(json['deletionTimestamp'])),
        'finalizers': !exists(json, 'finalizers') ? undefined : json['finalizers'],
        'generateName': !exists(json, 'generateName') ? undefined : json['generateName'],
        'generation': !exists(json, 'generation') ? undefined : json['generation'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'managedFields': !exists(json, 'managedFields') ? undefined : ((json['managedFields'] as Array<any>).map(V1ManagedFieldsEntryFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'ownerReferences': !exists(json, 'ownerReferences') ? undefined : ((json['ownerReferences'] as Array<any>).map(V1OwnerReferenceFromJSON)),
        'resourceVersion': !exists(json, 'resourceVersion') ? undefined : json['resourceVersion'],
        'selfLink': !exists(json, 'selfLink') ? undefined : json['selfLink'],
        'spec': !exists(json, 'spec') ? undefined : V1PersistentVolumeClaimSpecFromJSON(json['spec']),
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function V1PersistentVolumeClaimTemplateToJSON(value?: V1PersistentVolumeClaimTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'clusterName': value.clusterName,
        'creationTimestamp': value.creationTimestamp === undefined ? undefined : (value.creationTimestamp.toISOString()),
        'deletionGracePeriodSeconds': value.deletionGracePeriodSeconds,
        'deletionTimestamp': value.deletionTimestamp === undefined ? undefined : (value.deletionTimestamp.toISOString()),
        'finalizers': value.finalizers,
        'generateName': value.generateName,
        'generation': value.generation,
        'labels': value.labels,
        'managedFields': value.managedFields === undefined ? undefined : ((value.managedFields as Array<any>).map(V1ManagedFieldsEntryToJSON)),
        'name': value.name,
        'namespace': value.namespace,
        'ownerReferences': value.ownerReferences === undefined ? undefined : ((value.ownerReferences as Array<any>).map(V1OwnerReferenceToJSON)),
        'resourceVersion': value.resourceVersion,
        'selfLink': value.selfLink,
        'spec': V1PersistentVolumeClaimSpecToJSON(value.spec),
        'uid': value.uid,
    };
}

