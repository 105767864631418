/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1PortworxVolumeSource
 */
export interface V1PortworxVolumeSource {
    /**
     * FSType represents the filesystem type to mount
     * Must be a filesystem type supported by the host operating system.
     * Ex. "ext4", "xfs". Implicitly inferred to be "ext4" if unspecified.
     * @type {string}
     * @memberof V1PortworxVolumeSource
     */
    fsType?: string;
    /**
     * Defaults to false (read/write). ReadOnly here will force
     * the ReadOnly setting in VolumeMounts.
     * +optional
     * @type {boolean}
     * @memberof V1PortworxVolumeSource
     */
    readOnly?: boolean;
    /**
     * VolumeID uniquely identifies a Portworx volume
     * @type {string}
     * @memberof V1PortworxVolumeSource
     */
    volumeID?: string;
}

export function V1PortworxVolumeSourceFromJSON(json: any): V1PortworxVolumeSource {
    return V1PortworxVolumeSourceFromJSONTyped(json, false);
}

export function V1PortworxVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PortworxVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsType': !exists(json, 'fsType') ? undefined : json['fsType'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'volumeID': !exists(json, 'volumeID') ? undefined : json['volumeID'],
    };
}

export function V1PortworxVolumeSourceToJSON(value?: V1PortworxVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsType': value.fsType,
        'readOnly': value.readOnly,
        'volumeID': value.volumeID,
    };
}

