/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntstrIntOrString,
    IntstrIntOrStringFromJSON,
    IntstrIntOrStringFromJSONTyped,
    IntstrIntOrStringToJSON,
} from './IntstrIntOrString';
import {
    V1HTTPHeader,
    V1HTTPHeaderFromJSON,
    V1HTTPHeaderFromJSONTyped,
    V1HTTPHeaderToJSON,
} from './V1HTTPHeader';

/**
 * 
 * @export
 * @interface V1HTTPGetAction
 */
export interface V1HTTPGetAction {
    /**
     * Host name to connect to, defaults to the pod IP. You probably want to set
     * "Host" in httpHeaders instead.
     * +optional
     * @type {string}
     * @memberof V1HTTPGetAction
     */
    host?: string;
    /**
     * Custom headers to set in the request. HTTP allows repeated headers.
     * +optional
     * @type {Array<V1HTTPHeader>}
     * @memberof V1HTTPGetAction
     */
    httpHeaders?: Array<V1HTTPHeader>;
    /**
     * Path to access on the HTTP server.
     * +optional
     * @type {string}
     * @memberof V1HTTPGetAction
     */
    path?: string;
    /**
     * 
     * @type {IntstrIntOrString}
     * @memberof V1HTTPGetAction
     */
    port?: IntstrIntOrString;
    /**
     * URIScheme identifies the scheme used for connection to a host for Get actions
     * @type {string}
     * @memberof V1HTTPGetAction
     */
    scheme?: string;
}

export function V1HTTPGetActionFromJSON(json: any): V1HTTPGetAction {
    return V1HTTPGetActionFromJSONTyped(json, false);
}

export function V1HTTPGetActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1HTTPGetAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host': !exists(json, 'host') ? undefined : json['host'],
        'httpHeaders': !exists(json, 'httpHeaders') ? undefined : ((json['httpHeaders'] as Array<any>).map(V1HTTPHeaderFromJSON)),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'port': !exists(json, 'port') ? undefined : IntstrIntOrStringFromJSON(json['port']),
        'scheme': !exists(json, 'scheme') ? undefined : json['scheme'],
    };
}

export function V1HTTPGetActionToJSON(value?: V1HTTPGetAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host': value.host,
        'httpHeaders': value.httpHeaders === undefined ? undefined : ((value.httpHeaders as Array<any>).map(V1HTTPHeaderToJSON)),
        'path': value.path,
        'port': IntstrIntOrStringToJSON(value.port),
        'scheme': value.scheme,
    };
}

