/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiServiceType {
    NodePort = 'NodePort',
    Headless = 'Headless'
}

export function ApiServiceTypeFromJSON(json: any): ApiServiceType {
    return ApiServiceTypeFromJSONTyped(json, false);
}

export function ApiServiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServiceType {
    return json as ApiServiceType;
}

export function ApiServiceTypeToJSON(value?: ApiServiceType | null): any {
    return value as any;
}

