/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/binoculars/binoculars.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1PodLogOptions,
    V1PodLogOptionsFromJSON,
    V1PodLogOptionsFromJSONTyped,
    V1PodLogOptionsToJSON,
} from './V1PodLogOptions';

/**
 * 
 * @export
 * @interface BinocularsLogRequest
 */
export interface BinocularsLogRequest {
    /**
     * 
     * @type {string}
     * @memberof BinocularsLogRequest
     */
    jobId?: string;
    /**
     * 
     * @type {V1PodLogOptions}
     * @memberof BinocularsLogRequest
     */
    logOptions?: V1PodLogOptions;
    /**
     * 
     * @type {string}
     * @memberof BinocularsLogRequest
     */
    podNamespace?: string;
    /**
     * 
     * @type {number}
     * @memberof BinocularsLogRequest
     */
    podNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof BinocularsLogRequest
     */
    sinceTime?: string;
}

export function BinocularsLogRequestFromJSON(json: any): BinocularsLogRequest {
    return BinocularsLogRequestFromJSONTyped(json, false);
}

export function BinocularsLogRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinocularsLogRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'logOptions': !exists(json, 'logOptions') ? undefined : V1PodLogOptionsFromJSON(json['logOptions']),
        'podNamespace': !exists(json, 'podNamespace') ? undefined : json['podNamespace'],
        'podNumber': !exists(json, 'podNumber') ? undefined : json['podNumber'],
        'sinceTime': !exists(json, 'sinceTime') ? undefined : json['sinceTime'],
    };
}

export function BinocularsLogRequestToJSON(value?: BinocularsLogRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobId': value.jobId,
        'logOptions': V1PodLogOptionsToJSON(value.logOptions),
        'podNamespace': value.podNamespace,
        'podNumber': value.podNumber,
        'sinceTime': value.sinceTime,
    };
}

