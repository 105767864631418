/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1LocalObjectReference,
    V1LocalObjectReferenceFromJSON,
    V1LocalObjectReferenceFromJSONTyped,
    V1LocalObjectReferenceToJSON,
} from './V1LocalObjectReference';

/**
 * RBD volumes support ownership management and SELinux relabeling.
 * @export
 * @interface V1RBDVolumeSource
 */
export interface V1RBDVolumeSource {
    /**
     * Filesystem type of the volume that you want to mount.
     * Tip: Ensure that the filesystem type is supported by the host operating system.
     * Examples: "ext4", "xfs", "ntfs". Implicitly inferred to be "ext4" if unspecified.
     * More info: https://kubernetes.io/docs/concepts/storage/volumes#rbd
     * TODO: how do we prevent errors in the filesystem from compromising the machine
     * +optional
     * @type {string}
     * @memberof V1RBDVolumeSource
     */
    fsType?: string;
    /**
     * The rados image name.
     * More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
     * @type {string}
     * @memberof V1RBDVolumeSource
     */
    image?: string;
    /**
     * Keyring is the path to key ring for RBDUser.
     * Default is /etc/ceph/keyring.
     * More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
     * +optional
     * @type {string}
     * @memberof V1RBDVolumeSource
     */
    keyring?: string;
    /**
     * A collection of Ceph monitors.
     * More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
     * @type {Array<string>}
     * @memberof V1RBDVolumeSource
     */
    monitors?: Array<string>;
    /**
     * The rados pool name.
     * Default is rbd.
     * More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
     * +optional
     * @type {string}
     * @memberof V1RBDVolumeSource
     */
    pool?: string;
    /**
     * ReadOnly here will force the ReadOnly setting in VolumeMounts.
     * Defaults to false.
     * More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
     * +optional
     * @type {boolean}
     * @memberof V1RBDVolumeSource
     */
    readOnly?: boolean;
    /**
     * 
     * @type {V1LocalObjectReference}
     * @memberof V1RBDVolumeSource
     */
    secretRef?: V1LocalObjectReference;
    /**
     * The rados user name.
     * Default is admin.
     * More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
     * +optional
     * @type {string}
     * @memberof V1RBDVolumeSource
     */
    user?: string;
}

export function V1RBDVolumeSourceFromJSON(json: any): V1RBDVolumeSource {
    return V1RBDVolumeSourceFromJSONTyped(json, false);
}

export function V1RBDVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1RBDVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsType': !exists(json, 'fsType') ? undefined : json['fsType'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'keyring': !exists(json, 'keyring') ? undefined : json['keyring'],
        'monitors': !exists(json, 'monitors') ? undefined : json['monitors'],
        'pool': !exists(json, 'pool') ? undefined : json['pool'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'secretRef': !exists(json, 'secretRef') ? undefined : V1LocalObjectReferenceFromJSON(json['secretRef']),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function V1RBDVolumeSourceToJSON(value?: V1RBDVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsType': value.fsType,
        'image': value.image,
        'keyring': value.keyring,
        'monitors': value.monitors,
        'pool': value.pool,
        'readOnly': value.readOnly,
        'secretRef': V1LocalObjectReferenceToJSON(value.secretRef),
        'user': value.user,
    };
}

