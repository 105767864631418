/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookoutQueueInfo,
    LookoutQueueInfoFromJSON,
    LookoutQueueInfoFromJSONTyped,
    LookoutQueueInfoToJSON,
} from './LookoutQueueInfo';

/**
 * 
 * @export
 * @interface LookoutSystemOverview
 */
export interface LookoutSystemOverview {
    /**
     * 
     * @type {Array<LookoutQueueInfo>}
     * @memberof LookoutSystemOverview
     */
    queues?: Array<LookoutQueueInfo>;
}

export function LookoutSystemOverviewFromJSON(json: any): LookoutSystemOverview {
    return LookoutSystemOverviewFromJSONTyped(json, false);
}

export function LookoutSystemOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutSystemOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queues': !exists(json, 'queues') ? undefined : ((json['queues'] as Array<any>).map(LookoutQueueInfoFromJSON)),
    };
}

export function LookoutSystemOverviewToJSON(value?: LookoutSystemOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queues': value.queues === undefined ? undefined : ((value.queues as Array<any>).map(LookoutQueueInfoToJSON)),
    };
}

