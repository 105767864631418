/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiIngressType,
    ApiIngressTypeFromJSON,
    ApiIngressTypeFromJSONTyped,
    ApiIngressTypeToJSON,
} from './ApiIngressType';

/**
 * 
 * @export
 * @interface ApiIngressConfig
 */
export interface ApiIngressConfig {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiIngressConfig
     */
    annotations?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ApiIngressConfig
     */
    certName?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApiIngressConfig
     */
    ports?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ApiIngressConfig
     */
    tlsEnabled?: boolean;
    /**
     * 
     * @type {ApiIngressType}
     * @memberof ApiIngressConfig
     */
    type?: ApiIngressType;
    /**
     * 
     * @type {boolean}
     * @memberof ApiIngressConfig
     */
    useClusterIP?: boolean;
}

export function ApiIngressConfigFromJSON(json: any): ApiIngressConfig {
    return ApiIngressConfigFromJSONTyped(json, false);
}

export function ApiIngressConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiIngressConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'certName': !exists(json, 'certName') ? undefined : json['certName'],
        'ports': !exists(json, 'ports') ? undefined : json['ports'],
        'tlsEnabled': !exists(json, 'tlsEnabled') ? undefined : json['tlsEnabled'],
        'type': !exists(json, 'type') ? undefined : ApiIngressTypeFromJSON(json['type']),
        'useClusterIP': !exists(json, 'useClusterIP') ? undefined : json['useClusterIP'],
    };
}

export function ApiIngressConfigToJSON(value?: ApiIngressConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'certName': value.certName,
        'ports': value.ports,
        'tlsEnabled': value.tlsEnabled,
        'type': ApiIngressTypeToJSON(value.type),
        'useClusterIP': value.useClusterIP,
    };
}

