/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1IngressSpec,
    V1IngressSpecFromJSON,
    V1IngressSpecFromJSONTyped,
    V1IngressSpecToJSON,
} from './V1IngressSpec';
import {
    V1IngressStatus,
    V1IngressStatusFromJSON,
    V1IngressStatusFromJSONTyped,
    V1IngressStatusToJSON,
} from './V1IngressStatus';
import {
    V1ObjectMeta,
    V1ObjectMetaFromJSON,
    V1ObjectMetaFromJSONTyped,
    V1ObjectMetaToJSON,
} from './V1ObjectMeta';

/**
 * Ingress is a collection of rules that allow inbound connections to reach the
 * endpoints defined by a backend. An Ingress can be configured to give services
 * externally-reachable urls, load balance traffic, terminate SSL, offer name
 * based virtual hosting etc.
 * @export
 * @interface V1Ingress
 */
export interface V1Ingress {
    /**
     * 
     * @type {V1ObjectMeta}
     * @memberof V1Ingress
     */
    metadata?: V1ObjectMeta;
    /**
     * 
     * @type {V1IngressSpec}
     * @memberof V1Ingress
     */
    spec?: V1IngressSpec;
    /**
     * 
     * @type {V1IngressStatus}
     * @memberof V1Ingress
     */
    status?: V1IngressStatus;
}

export function V1IngressFromJSON(json: any): V1Ingress {
    return V1IngressFromJSONTyped(json, false);
}

export function V1IngressFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Ingress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : V1ObjectMetaFromJSON(json['metadata']),
        'spec': !exists(json, 'spec') ? undefined : V1IngressSpecFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : V1IngressStatusFromJSON(json['status']),
    };
}

export function V1IngressToJSON(value?: V1Ingress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': V1ObjectMetaToJSON(value.metadata),
        'spec': V1IngressSpecToJSON(value.spec),
        'status': V1IngressStatusToJSON(value.status),
    };
}

