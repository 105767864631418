/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiCause {
    Error = 'Error',
    Evicted = 'Evicted',
    Oom = 'OOM',
    DeadlineExceeded = 'DeadlineExceeded'
}

export function ApiCauseFromJSON(json: any): ApiCause {
    return ApiCauseFromJSONTyped(json, false);
}

export function ApiCauseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCause {
    return json as ApiCause;
}

export function ApiCauseToJSON(value?: ApiCause | null): any {
    return value as any;
}

