/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionsSubject,
    PermissionsSubjectFromJSON,
    PermissionsSubjectFromJSONTyped,
    PermissionsSubjectToJSON,
} from './PermissionsSubject';

/**
 * 
 * @export
 * @interface QueuePermissions
 */
export interface QueuePermissions {
    /**
     * 
     * @type {Array<PermissionsSubject>}
     * @memberof QueuePermissions
     */
    subjects?: Array<PermissionsSubject>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueuePermissions
     */
    verbs?: Array<string>;
}

export function QueuePermissionsFromJSON(json: any): QueuePermissions {
    return QueuePermissionsFromJSONTyped(json, false);
}

export function QueuePermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueuePermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjects': !exists(json, 'subjects') ? undefined : ((json['subjects'] as Array<any>).map(PermissionsSubjectFromJSON)),
        'verbs': !exists(json, 'verbs') ? undefined : json['verbs'],
    };
}

export function QueuePermissionsToJSON(value?: QueuePermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjects': value.subjects === undefined ? undefined : ((value.subjects as Array<any>).map(PermissionsSubjectToJSON)),
        'verbs': value.verbs,
    };
}

