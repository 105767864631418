/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookoutRunInfo
 */
export interface LookoutRunInfo {
    /**
     * 
     * @type {string}
     * @memberof LookoutRunInfo
     */
    cluster?: string;
    /**
     * 
     * @type {Date}
     * @memberof LookoutRunInfo
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof LookoutRunInfo
     */
    error?: string;
    /**
     * 
     * @type {Date}
     * @memberof LookoutRunInfo
     */
    finished?: Date;
    /**
     * 
     * @type {string}
     * @memberof LookoutRunInfo
     */
    k8sId?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutRunInfo
     */
    node?: string;
    /**
     * 
     * @type {number}
     * @memberof LookoutRunInfo
     */
    podNumber?: number;
    /**
     * 
     * @type {Date}
     * @memberof LookoutRunInfo
     */
    preempted?: Date;
    /**
     * 
     * @type {string}
     * @memberof LookoutRunInfo
     */
    runState?: string;
    /**
     * 
     * @type {Date}
     * @memberof LookoutRunInfo
     */
    started?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LookoutRunInfo
     */
    succeeded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LookoutRunInfo
     */
    unableToSchedule?: boolean;
}

export function LookoutRunInfoFromJSON(json: any): LookoutRunInfo {
    return LookoutRunInfoFromJSONTyped(json, false);
}

export function LookoutRunInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutRunInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cluster': !exists(json, 'cluster') ? undefined : json['cluster'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'finished': !exists(json, 'finished') ? undefined : (new Date(json['finished'])),
        'k8sId': !exists(json, 'k8sId') ? undefined : json['k8sId'],
        'node': !exists(json, 'node') ? undefined : json['node'],
        'podNumber': !exists(json, 'podNumber') ? undefined : json['podNumber'],
        'preempted': !exists(json, 'preempted') ? undefined : (new Date(json['preempted'])),
        'runState': !exists(json, 'runState') ? undefined : json['runState'],
        'started': !exists(json, 'started') ? undefined : (new Date(json['started'])),
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'unableToSchedule': !exists(json, 'unableToSchedule') ? undefined : json['unableToSchedule'],
    };
}

export function LookoutRunInfoToJSON(value?: LookoutRunInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cluster': value.cluster,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'error': value.error,
        'finished': value.finished === undefined ? undefined : (value.finished.toISOString()),
        'k8sId': value.k8sId,
        'node': value.node,
        'podNumber': value.podNumber,
        'preempted': value.preempted === undefined ? undefined : (value.preempted.toISOString()),
        'runState': value.runState,
        'started': value.started === undefined ? undefined : (value.started.toISOString()),
        'succeeded': value.succeeded,
        'unableToSchedule': value.unableToSchedule,
    };
}

