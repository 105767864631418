/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiIngressConfig,
    ApiIngressConfigFromJSON,
    ApiIngressConfigFromJSONTyped,
    ApiIngressConfigToJSON,
} from './ApiIngressConfig';
import {
    ApiServiceConfig,
    ApiServiceConfigFromJSON,
    ApiServiceConfigFromJSONTyped,
    ApiServiceConfigToJSON,
} from './ApiServiceConfig';
import {
    V1PodSpec,
    V1PodSpecFromJSON,
    V1PodSpecFromJSONTyped,
    V1PodSpecToJSON,
} from './V1PodSpec';

/**
 * 
 * @export
 * @interface ApiJobSubmitRequestItem
 */
export interface ApiJobSubmitRequestItem {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiJobSubmitRequestItem
     */
    annotations?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ApiJobSubmitRequestItem
     */
    clientId?: string;
    /**
     * 
     * @type {Array<ApiIngressConfig>}
     * @memberof ApiJobSubmitRequestItem
     */
    ingress?: Array<ApiIngressConfig>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiJobSubmitRequestItem
     */
    labels?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ApiJobSubmitRequestItem
     */
    namespace?: string;
    /**
     * 
     * @type {V1PodSpec}
     * @memberof ApiJobSubmitRequestItem
     */
    podSpec?: V1PodSpec;
    /**
     * 
     * @type {Array<V1PodSpec>}
     * @memberof ApiJobSubmitRequestItem
     */
    podSpecs?: Array<V1PodSpec>;
    /**
     * 
     * @type {number}
     * @memberof ApiJobSubmitRequestItem
     */
    priority?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiJobSubmitRequestItem
     */
    requiredNodeLabels?: { [key: string]: string; };
    /**
     * Indicates which scheduler should manage this job.
     * If empty, the default scheduler is used.
     * @type {string}
     * @memberof ApiJobSubmitRequestItem
     */
    scheduler?: string;
    /**
     * 
     * @type {Array<ApiServiceConfig>}
     * @memberof ApiJobSubmitRequestItem
     */
    services?: Array<ApiServiceConfig>;
}

export function ApiJobSubmitRequestItemFromJSON(json: any): ApiJobSubmitRequestItem {
    return ApiJobSubmitRequestItemFromJSONTyped(json, false);
}

export function ApiJobSubmitRequestItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJobSubmitRequestItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'ingress': !exists(json, 'ingress') ? undefined : ((json['ingress'] as Array<any>).map(ApiIngressConfigFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'podSpec': !exists(json, 'podSpec') ? undefined : V1PodSpecFromJSON(json['podSpec']),
        'podSpecs': !exists(json, 'podSpecs') ? undefined : ((json['podSpecs'] as Array<any>).map(V1PodSpecFromJSON)),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'requiredNodeLabels': !exists(json, 'requiredNodeLabels') ? undefined : json['requiredNodeLabels'],
        'scheduler': !exists(json, 'scheduler') ? undefined : json['scheduler'],
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(ApiServiceConfigFromJSON)),
    };
}

export function ApiJobSubmitRequestItemToJSON(value?: ApiJobSubmitRequestItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'clientId': value.clientId,
        'ingress': value.ingress === undefined ? undefined : ((value.ingress as Array<any>).map(ApiIngressConfigToJSON)),
        'labels': value.labels,
        'namespace': value.namespace,
        'podSpec': V1PodSpecToJSON(value.podSpec),
        'podSpecs': value.podSpecs === undefined ? undefined : ((value.podSpecs as Array<any>).map(V1PodSpecToJSON)),
        'priority': value.priority,
        'requiredNodeLabels': value.requiredNodeLabels,
        'scheduler': value.scheduler,
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(ApiServiceConfigToJSON)),
    };
}

