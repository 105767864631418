/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueuePermissions,
    QueuePermissionsFromJSON,
    QueuePermissionsFromJSONTyped,
    QueuePermissionsToJSON,
} from './QueuePermissions';

/**
 * 
 * @export
 * @interface ApiQueue
 */
export interface ApiQueue {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiQueue
     */
    groupOwners?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiQueue
     */
    name?: string;
    /**
     * 
     * @type {Array<QueuePermissions>}
     * @memberof ApiQueue
     */
    permissions?: Array<QueuePermissions>;
    /**
     * 
     * @type {number}
     * @memberof ApiQueue
     */
    priorityFactor?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ApiQueue
     */
    resourceLimits?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiQueue
     */
    userOwners?: Array<string>;
}

export function ApiQueueFromJSON(json: any): ApiQueue {
    return ApiQueueFromJSONTyped(json, false);
}

export function ApiQueueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiQueue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupOwners': !exists(json, 'groupOwners') ? undefined : json['groupOwners'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(QueuePermissionsFromJSON)),
        'priorityFactor': !exists(json, 'priorityFactor') ? undefined : json['priorityFactor'],
        'resourceLimits': !exists(json, 'resourceLimits') ? undefined : json['resourceLimits'],
        'userOwners': !exists(json, 'userOwners') ? undefined : json['userOwners'],
    };
}

export function ApiQueueToJSON(value?: ApiQueue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupOwners': value.groupOwners,
        'name': value.name,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(QueuePermissionsToJSON)),
        'priorityFactor': value.priorityFactor,
        'resourceLimits': value.resourceLimits,
        'userOwners': value.userOwners,
    };
}

