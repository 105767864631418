/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookoutDurationStats,
    LookoutDurationStatsFromJSON,
    LookoutDurationStatsFromJSONTyped,
    LookoutDurationStatsToJSON,
} from './LookoutDurationStats';

/**
 * 
 * @export
 * @interface LookoutJobSetInfo
 */
export interface LookoutJobSetInfo {
    /**
     * 
     * @type {string}
     * @memberof LookoutJobSetInfo
     */
    jobSet?: string;
    /**
     * 
     * @type {number}
     * @memberof LookoutJobSetInfo
     */
    jobsCancelled?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutJobSetInfo
     */
    jobsFailed?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutJobSetInfo
     */
    jobsPending?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutJobSetInfo
     */
    jobsQueued?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutJobSetInfo
     */
    jobsRunning?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutJobSetInfo
     */
    jobsSucceeded?: number;
    /**
     * 
     * @type {string}
     * @memberof LookoutJobSetInfo
     */
    queue?: string;
    /**
     * 
     * @type {LookoutDurationStats}
     * @memberof LookoutJobSetInfo
     */
    queuedStats?: LookoutDurationStats;
    /**
     * 
     * @type {LookoutDurationStats}
     * @memberof LookoutJobSetInfo
     */
    runningStats?: LookoutDurationStats;
    /**
     * 
     * @type {Date}
     * @memberof LookoutJobSetInfo
     */
    submitted?: Date;
}

export function LookoutJobSetInfoFromJSON(json: any): LookoutJobSetInfo {
    return LookoutJobSetInfoFromJSONTyped(json, false);
}

export function LookoutJobSetInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutJobSetInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobSet': !exists(json, 'jobSet') ? undefined : json['jobSet'],
        'jobsCancelled': !exists(json, 'jobsCancelled') ? undefined : json['jobsCancelled'],
        'jobsFailed': !exists(json, 'jobsFailed') ? undefined : json['jobsFailed'],
        'jobsPending': !exists(json, 'jobsPending') ? undefined : json['jobsPending'],
        'jobsQueued': !exists(json, 'jobsQueued') ? undefined : json['jobsQueued'],
        'jobsRunning': !exists(json, 'jobsRunning') ? undefined : json['jobsRunning'],
        'jobsSucceeded': !exists(json, 'jobsSucceeded') ? undefined : json['jobsSucceeded'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'queuedStats': !exists(json, 'queuedStats') ? undefined : LookoutDurationStatsFromJSON(json['queuedStats']),
        'runningStats': !exists(json, 'runningStats') ? undefined : LookoutDurationStatsFromJSON(json['runningStats']),
        'submitted': !exists(json, 'submitted') ? undefined : (new Date(json['submitted'])),
    };
}

export function LookoutJobSetInfoToJSON(value?: LookoutJobSetInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobSet': value.jobSet,
        'jobsCancelled': value.jobsCancelled,
        'jobsFailed': value.jobsFailed,
        'jobsPending': value.jobsPending,
        'jobsQueued': value.jobsQueued,
        'jobsRunning': value.jobsRunning,
        'jobsSucceeded': value.jobsSucceeded,
        'queue': value.queue,
        'queuedStats': LookoutDurationStatsToJSON(value.queuedStats),
        'runningStats': LookoutDurationStatsToJSON(value.runningStats),
        'submitted': value.submitted === undefined ? undefined : (value.submitted.toISOString()),
    };
}

