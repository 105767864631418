/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiJobState {
    Queued = 'QUEUED',
    Pending = 'PENDING',
    Running = 'RUNNING',
    Succeeded = 'SUCCEEDED',
    Failed = 'FAILED',
    Unknown = 'UNKNOWN'
}

export function ApiJobStateFromJSON(json: any): ApiJobState {
    return ApiJobStateFromJSONTyped(json, false);
}

export function ApiJobStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJobState {
    return json as ApiJobState;
}

export function ApiJobStateToJSON(value?: ApiJobState | null): any {
    return value as any;
}

