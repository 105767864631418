/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1NodeSelectorRequirement,
    V1NodeSelectorRequirementFromJSON,
    V1NodeSelectorRequirementFromJSONTyped,
    V1NodeSelectorRequirementToJSON,
} from './V1NodeSelectorRequirement';

/**
 * A null or empty node selector term matches no objects. The requirements of
 * them are ANDed.
 * The TopologySelectorTerm type implements a subset of the NodeSelectorTerm.
 * +structType=atomic
 * @export
 * @interface V1NodeSelectorTerm
 */
export interface V1NodeSelectorTerm {
    /**
     * A list of node selector requirements by node's labels.
     * +optional
     * @type {Array<V1NodeSelectorRequirement>}
     * @memberof V1NodeSelectorTerm
     */
    matchExpressions?: Array<V1NodeSelectorRequirement>;
    /**
     * A list of node selector requirements by node's fields.
     * +optional
     * @type {Array<V1NodeSelectorRequirement>}
     * @memberof V1NodeSelectorTerm
     */
    matchFields?: Array<V1NodeSelectorRequirement>;
}

export function V1NodeSelectorTermFromJSON(json: any): V1NodeSelectorTerm {
    return V1NodeSelectorTermFromJSONTyped(json, false);
}

export function V1NodeSelectorTermFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1NodeSelectorTerm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matchExpressions': !exists(json, 'matchExpressions') ? undefined : ((json['matchExpressions'] as Array<any>).map(V1NodeSelectorRequirementFromJSON)),
        'matchFields': !exists(json, 'matchFields') ? undefined : ((json['matchFields'] as Array<any>).map(V1NodeSelectorRequirementFromJSON)),
    };
}

export function V1NodeSelectorTermToJSON(value?: V1NodeSelectorTerm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matchExpressions': value.matchExpressions === undefined ? undefined : ((value.matchExpressions as Array<any>).map(V1NodeSelectorRequirementToJSON)),
        'matchFields': value.matchFields === undefined ? undefined : ((value.matchFields as Array<any>).map(V1NodeSelectorRequirementToJSON)),
    };
}

