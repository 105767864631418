/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiJobSubmitRequestItem,
    ApiJobSubmitRequestItemFromJSON,
    ApiJobSubmitRequestItemFromJSONTyped,
    ApiJobSubmitRequestItemToJSON,
} from './ApiJobSubmitRequestItem';

/**
 * 
 * @export
 * @interface ApiJobSubmitRequest
 */
export interface ApiJobSubmitRequest {
    /**
     * 
     * @type {Array<ApiJobSubmitRequestItem>}
     * @memberof ApiJobSubmitRequest
     */
    jobRequestItems?: Array<ApiJobSubmitRequestItem>;
    /**
     * 
     * @type {string}
     * @memberof ApiJobSubmitRequest
     */
    jobSetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJobSubmitRequest
     */
    queue?: string;
}

export function ApiJobSubmitRequestFromJSON(json: any): ApiJobSubmitRequest {
    return ApiJobSubmitRequestFromJSONTyped(json, false);
}

export function ApiJobSubmitRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJobSubmitRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobRequestItems': !exists(json, 'jobRequestItems') ? undefined : ((json['jobRequestItems'] as Array<any>).map(ApiJobSubmitRequestItemFromJSON)),
        'jobSetId': !exists(json, 'jobSetId') ? undefined : json['jobSetId'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
    };
}

export function ApiJobSubmitRequestToJSON(value?: ApiJobSubmitRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobRequestItems': value.jobRequestItems === undefined ? undefined : ((value.jobRequestItems as Array<any>).map(ApiJobSubmitRequestItemToJSON)),
        'jobSetId': value.jobSetId,
        'queue': value.queue,
    };
}

