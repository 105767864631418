/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiJobSetFilter,
    ApiJobSetFilterFromJSON,
    ApiJobSetFilterFromJSONTyped,
    ApiJobSetFilterToJSON,
} from './ApiJobSetFilter';

/**
 * 
 * @export
 * @interface ApiJobSetCancelRequest
 */
export interface ApiJobSetCancelRequest {
    /**
     * 
     * @type {ApiJobSetFilter}
     * @memberof ApiJobSetCancelRequest
     */
    filter?: ApiJobSetFilter;
    /**
     * 
     * @type {string}
     * @memberof ApiJobSetCancelRequest
     */
    jobSetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJobSetCancelRequest
     */
    queue?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJobSetCancelRequest
     */
    reason?: string;
}

export function ApiJobSetCancelRequestFromJSON(json: any): ApiJobSetCancelRequest {
    return ApiJobSetCancelRequestFromJSONTyped(json, false);
}

export function ApiJobSetCancelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJobSetCancelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filter': !exists(json, 'filter') ? undefined : ApiJobSetFilterFromJSON(json['filter']),
        'jobSetId': !exists(json, 'jobSetId') ? undefined : json['jobSetId'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function ApiJobSetCancelRequestToJSON(value?: ApiJobSetCancelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter': ApiJobSetFilterToJSON(value.filter),
        'jobSetId': value.jobSetId,
        'queue': value.queue,
        'reason': value.reason,
    };
}

