/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ManagedFieldsEntry is a workflow-id, a FieldSet and the group version of the resource
 * that the fieldset applies to.
 * @export
 * @interface V1ManagedFieldsEntry
 */
export interface V1ManagedFieldsEntry {
    /**
     * APIVersion defines the version of this resource that this field set
     * applies to. The format is "group/version" just like the top-level
     * APIVersion field. It is necessary to track the version of a field
     * set because it cannot be automatically converted.
     * @type {string}
     * @memberof V1ManagedFieldsEntry
     */
    apiVersion?: string;
    /**
     * FieldsType is the discriminator for the different fields format and version.
     * There is currently only one possible value: "FieldsV1"
     * @type {string}
     * @memberof V1ManagedFieldsEntry
     */
    fieldsType?: string;
    /**
     * Each key is either a '.' representing the field itself, and will always map to an empty set,
     * or a string representing a sub-field or item. The string will follow one of these four formats:
     * 'f:<name>', where <name> is the name of a field in a struct, or key in a map
     * 'v:<value>', where <value> is the exact json formatted value of a list item
     * 'i:<index>', where <index> is position of a item in a list
     * 'k:<keys>', where <keys> is a map of  a list item's key fields to their unique values
     * If a key maps to an empty Fields value, the field that key represents is part of the set.
     * 
     * The exact format is defined in sigs.k8s.io/structured-merge-diff
     * +protobuf.options.(gogoproto.goproto_stringer)=false
     * @type {object}
     * @memberof V1ManagedFieldsEntry
     */
    fieldsV1?: object;
    /**
     * Manager is an identifier of the workflow managing these fields.
     * @type {string}
     * @memberof V1ManagedFieldsEntry
     */
    manager?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ManagedFieldsEntry
     */
    operation?: string;
    /**
     * Subresource is the name of the subresource used to update that object, or
     * empty string if the object was updated through the main resource. The
     * value of this field is used to distinguish between managers, even if they
     * share the same name. For example, a status update will be distinct from a
     * regular update using the same manager name.
     * Note that the APIVersion field is not related to the Subresource field and
     * it always corresponds to the version of the main resource.
     * @type {string}
     * @memberof V1ManagedFieldsEntry
     */
    subresource?: string;
    /**
     * Programs using times should typically store and pass them as values,
     * not pointers. That is, time variables and struct fields should be of
     * type time.Time, not *time.Time.
     * 
     * A Time value can be used by multiple goroutines simultaneously except
     * that the methods GobDecode, UnmarshalBinary, UnmarshalJSON and
     * UnmarshalText are not concurrency-safe.
     * 
     * Time instants can be compared using the Before, After, and Equal methods.
     * The Sub method subtracts two instants, producing a Duration.
     * The Add method adds a Time and a Duration, producing a Time.
     * 
     * The zero value of type Time is January 1, year 1, 00:00:00.000000000 UTC.
     * As this time is unlikely to come up in practice, the IsZero method gives
     * a simple way of detecting a time that has not been initialized explicitly.
     * 
     * Each Time has associated with it a Location, consulted when computing the
     * presentation form of the time, such as in the Format, Hour, and Year methods.
     * The methods Local, UTC, and In return a Time with a specific location.
     * Changing the location in this way changes only the presentation; it does not
     * change the instant in time being denoted and therefore does not affect the
     * computations described in earlier paragraphs.
     * 
     * Representations of a Time value saved by the GobEncode, MarshalBinary,
     * MarshalJSON, and MarshalText methods store the Time.Location's offset, but not
     * the location name. They therefore lose information about Daylight Saving Time.
     * 
     * In addition to the required “wall clock” reading, a Time may contain an optional
     * reading of the current process's monotonic clock, to provide additional precision
     * for comparison or subtraction.
     * See the “Monotonic Clocks” section in the package documentation for details.
     * 
     * Note that the Go == operator compares not just the time instant but also the
     * Location and the monotonic clock reading. Therefore, Time values should not
     * be used as map or database keys without first guaranteeing that the
     * identical Location has been set for all values, which can be achieved
     * through use of the UTC or Local method, and that the monotonic clock reading
     * has been stripped by setting t = t.Round(0). In general, prefer t.Equal(u)
     * to t == u, since t.Equal uses the most accurate comparison available and
     * correctly handles the case when only one of its arguments has a monotonic
     * clock reading.
     * @type {Date}
     * @memberof V1ManagedFieldsEntry
     */
    time?: Date;
}

export function V1ManagedFieldsEntryFromJSON(json: any): V1ManagedFieldsEntry {
    return V1ManagedFieldsEntryFromJSONTyped(json, false);
}

export function V1ManagedFieldsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ManagedFieldsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': !exists(json, 'apiVersion') ? undefined : json['apiVersion'],
        'fieldsType': !exists(json, 'fieldsType') ? undefined : json['fieldsType'],
        'fieldsV1': !exists(json, 'fieldsV1') ? undefined : json['fieldsV1'],
        'manager': !exists(json, 'manager') ? undefined : json['manager'],
        'operation': !exists(json, 'operation') ? undefined : json['operation'],
        'subresource': !exists(json, 'subresource') ? undefined : json['subresource'],
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
    };
}

export function V1ManagedFieldsEntryToJSON(value?: V1ManagedFieldsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': value.apiVersion,
        'fieldsType': value.fieldsType,
        'fieldsV1': value.fieldsV1,
        'manager': value.manager,
        'operation': value.operation,
        'subresource': value.subresource,
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
    };
}

