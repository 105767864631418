/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ResourceFieldSelector represents container resources (cpu, memory) and their output format
 * +structType=atomic
 * @export
 * @interface V1ResourceFieldSelector
 */
export interface V1ResourceFieldSelector {
    /**
     * Container name: required for volumes, optional for env vars
     * +optional
     * @type {string}
     * @memberof V1ResourceFieldSelector
     */
    containerName?: string;
    /**
     * The serialization format is:
     * 
     * <quantity>        ::= <signedNumber><suffix>
     * (Note that <suffix> may be empty, from the "" case in <decimalSI>.)
     * <digit>           ::= 0 | 1 | ... | 9
     * <digits>          ::= <digit> | <digit><digits>
     * <number>          ::= <digits> | <digits>.<digits> | <digits>. | .<digits>
     * <sign>            ::= "+" | "-"
     * <signedNumber>    ::= <number> | <sign><number>
     * <suffix>          ::= <binarySI> | <decimalExponent> | <decimalSI>
     * <binarySI>        ::= Ki | Mi | Gi | Ti | Pi | Ei
     * (International System of units; See: http://physics.nist.gov/cuu/Units/binary.html)
     * <decimalSI>       ::= m | "" | k | M | G | T | P | E
     * (Note that 1024 = 1Ki but 1000 = 1k; I didn't choose the capitalization.)
     * <decimalExponent> ::= "e" <signedNumber> | "E" <signedNumber>
     * 
     * No matter which of the three exponent forms is used, no quantity may represent
     * a number greater than 2^63-1 in magnitude, nor may it have more than 3 decimal
     * places. Numbers larger or more precise will be capped or rounded up.
     * (E.g.: 0.1m will rounded up to 1m.)
     * This may be extended in the future if we require larger or smaller quantities.
     * 
     * When a Quantity is parsed from a string, it will remember the type of suffix
     * it had, and will use the same type again when it is serialized.
     * 
     * Before serializing, Quantity will be put in "canonical form".
     * This means that Exponent/suffix will be adjusted up or down (with a
     * corresponding increase or decrease in Mantissa) such that:
     * a. No precision is lost
     * b. No fractional digits will be emitted
     * c. The exponent (or suffix) is as large as possible.
     * The sign will be omitted unless the number is negative.
     * 
     * Examples:
     * 1.5 will be serialized as "1500m"
     * 1.5Gi will be serialized as "1536Mi"
     * 
     * Note that the quantity will NEVER be internally represented by a
     * floating point number. That is the whole point of this exercise.
     * 
     * Non-canonical values will still parse as long as they are well formed,
     * but will be re-emitted in their canonical form. (So always use canonical
     * form, or don't diff.)
     * 
     * This format is intended to make it difficult to use these numbers without
     * writing some sort of special handling code in the hopes that that will
     * cause implementors to also use a fixed point implementation.
     * 
     * +protobuf=true
     * +protobuf.embed=string
     * +protobuf.options.marshal=false
     * +protobuf.options.(gogoproto.goproto_stringer)=false
     * +k8s:deepcopy-gen=true
     * +k8s:openapi-gen=true
     * @type {string}
     * @memberof V1ResourceFieldSelector
     */
    divisor?: string;
    /**
     * Required: resource to select
     * @type {string}
     * @memberof V1ResourceFieldSelector
     */
    resource?: string;
}

export function V1ResourceFieldSelectorFromJSON(json: any): V1ResourceFieldSelector {
    return V1ResourceFieldSelectorFromJSONTyped(json, false);
}

export function V1ResourceFieldSelectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ResourceFieldSelector {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerName': !exists(json, 'containerName') ? undefined : json['containerName'],
        'divisor': !exists(json, 'divisor') ? undefined : json['divisor'],
        'resource': !exists(json, 'resource') ? undefined : json['resource'],
    };
}

export function V1ResourceFieldSelectorToJSON(value?: V1ResourceFieldSelector | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerName': value.containerName,
        'divisor': value.divisor,
        'resource': value.resource,
    };
}

