/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Ingress type is being kept here to maintain backwards compatibility for a while.
 * @export
 * @enum {string}
 */
export enum ApiIngressType {
    Ingress = 'Ingress'
}

export function ApiIngressTypeFromJSON(json: any): ApiIngressType {
    return ApiIngressTypeFromJSONTyped(json, false);
}

export function ApiIngressTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiIngressType {
    return json as ApiIngressType;
}

export function ApiIngressTypeToJSON(value?: ApiIngressType | null): any {
    return value as any;
}

