/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookoutJobInfo,
    LookoutJobInfoFromJSON,
    LookoutJobInfoFromJSONTyped,
    LookoutJobInfoToJSON,
} from './LookoutJobInfo';

/**
 * 
 * @export
 * @interface LookoutGetJobsResponse
 */
export interface LookoutGetJobsResponse {
    /**
     * 
     * @type {Array<LookoutJobInfo>}
     * @memberof LookoutGetJobsResponse
     */
    jobInfos?: Array<LookoutJobInfo>;
}

export function LookoutGetJobsResponseFromJSON(json: any): LookoutGetJobsResponse {
    return LookoutGetJobsResponseFromJSONTyped(json, false);
}

export function LookoutGetJobsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutGetJobsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobInfos': !exists(json, 'jobInfos') ? undefined : ((json['jobInfos'] as Array<any>).map(LookoutJobInfoFromJSON)),
    };
}

export function LookoutGetJobsResponseToJSON(value?: LookoutGetJobsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobInfos': value.jobInfos === undefined ? undefined : ((value.jobInfos as Array<any>).map(LookoutJobInfoToJSON)),
    };
}

