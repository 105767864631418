/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntstrIntOrString,
    IntstrIntOrStringFromJSON,
    IntstrIntOrStringFromJSONTyped,
    IntstrIntOrStringToJSON,
} from './IntstrIntOrString';

/**
 * TCPSocketAction describes an action based on opening a socket
 * @export
 * @interface V1TCPSocketAction
 */
export interface V1TCPSocketAction {
    /**
     * Optional: Host name to connect to, defaults to the pod IP.
     * +optional
     * @type {string}
     * @memberof V1TCPSocketAction
     */
    host?: string;
    /**
     * 
     * @type {IntstrIntOrString}
     * @memberof V1TCPSocketAction
     */
    port?: IntstrIntOrString;
}

export function V1TCPSocketActionFromJSON(json: any): V1TCPSocketAction {
    return V1TCPSocketActionFromJSONTyped(json, false);
}

export function V1TCPSocketActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1TCPSocketAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host': !exists(json, 'host') ? undefined : json['host'],
        'port': !exists(json, 'port') ? undefined : IntstrIntOrStringFromJSON(json['port']),
    };
}

export function V1TCPSocketActionToJSON(value?: V1TCPSocketAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host': value.host,
        'port': IntstrIntOrStringToJSON(value.port),
    };
}

