/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookoutGetJobsRequest
 */
export interface LookoutGetJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof LookoutGetJobsRequest
     */
    jobId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LookoutGetJobsRequest
     */
    jobSetIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LookoutGetJobsRequest
     */
    jobStates?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof LookoutGetJobsRequest
     */
    newestFirst?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LookoutGetJobsRequest
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof LookoutGetJobsRequest
     */
    queue?: string;
    /**
     * 
     * @type {number}
     * @memberof LookoutGetJobsRequest
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof LookoutGetJobsRequest
     */
    take?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LookoutGetJobsRequest
     */
    userAnnotations?: { [key: string]: string; };
}

export function LookoutGetJobsRequestFromJSON(json: any): LookoutGetJobsRequest {
    return LookoutGetJobsRequestFromJSONTyped(json, false);
}

export function LookoutGetJobsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookoutGetJobsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'jobSetIds': !exists(json, 'jobSetIds') ? undefined : json['jobSetIds'],
        'jobStates': !exists(json, 'jobStates') ? undefined : json['jobStates'],
        'newestFirst': !exists(json, 'newestFirst') ? undefined : json['newestFirst'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'skip': !exists(json, 'skip') ? undefined : json['skip'],
        'take': !exists(json, 'take') ? undefined : json['take'],
        'userAnnotations': !exists(json, 'userAnnotations') ? undefined : json['userAnnotations'],
    };
}

export function LookoutGetJobsRequestToJSON(value?: LookoutGetJobsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobId': value.jobId,
        'jobSetIds': value.jobSetIds,
        'jobStates': value.jobStates,
        'newestFirst': value.newestFirst,
        'owner': value.owner,
        'queue': value.queue,
        'skip': value.skip,
        'take': value.take,
        'userAnnotations': value.userAnnotations,
    };
}

