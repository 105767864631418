/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/binoculars/binoculars.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BinocularsLogLine
 */
export interface BinocularsLogLine {
    /**
     * 
     * @type {string}
     * @memberof BinocularsLogLine
     */
    line?: string;
    /**
     * 
     * @type {string}
     * @memberof BinocularsLogLine
     */
    timestamp?: string;
}

export function BinocularsLogLineFromJSON(json: any): BinocularsLogLine {
    return BinocularsLogLineFromJSONTyped(json, false);
}

export function BinocularsLogLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinocularsLogLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line': !exists(json, 'line') ? undefined : json['line'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function BinocularsLogLineToJSON(value?: BinocularsLogLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line': value.line,
        'timestamp': value.timestamp,
    };
}

