/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/binoculars/binoculars.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BinocularsCordonRequest,
    BinocularsCordonRequestFromJSON,
    BinocularsCordonRequestToJSON,
    BinocularsLogRequest,
    BinocularsLogRequestFromJSON,
    BinocularsLogRequestToJSON,
    BinocularsLogResponse,
    BinocularsLogResponseFromJSON,
    BinocularsLogResponseToJSON,
    RuntimeError,
    RuntimeErrorFromJSON,
    RuntimeErrorToJSON,
} from '../models';

export interface CordonRequest {
    body: BinocularsCordonRequest;
}

export interface LogsRequest {
    body: BinocularsLogRequest;
}

/**
 * 
 */
export class BinocularsApi extends runtime.BaseAPI {

    /**
     */
    async cordonRaw(requestParameters: CordonRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling cordon.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/binoculars/cordon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BinocularsCordonRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async cordon(requestParameters: CordonRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.cordonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logsRaw(requestParameters: LogsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BinocularsLogResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling logs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/binoculars/log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BinocularsLogRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinocularsLogResponseFromJSON(jsonValue));
    }

    /**
     */
    async logs(requestParameters: LogsRequest, initOverrides?: RequestInit): Promise<BinocularsLogResponse> {
        const response = await this.logsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
