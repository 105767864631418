/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1LocalObjectReference,
    V1LocalObjectReferenceFromJSON,
    V1LocalObjectReferenceFromJSONTyped,
    V1LocalObjectReferenceToJSON,
} from './V1LocalObjectReference';

/**
 * ScaleIOVolumeSource represents a persistent ScaleIO volume
 * @export
 * @interface V1ScaleIOVolumeSource
 */
export interface V1ScaleIOVolumeSource {
    /**
     * Filesystem type to mount.
     * Must be a filesystem type supported by the host operating system.
     * Ex. "ext4", "xfs", "ntfs".
     * Default is "xfs".
     * +optional
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    fsType?: string;
    /**
     * The host address of the ScaleIO API Gateway.
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    gateway?: string;
    /**
     * The name of the ScaleIO Protection Domain for the configured storage.
     * +optional
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    protectionDomain?: string;
    /**
     * Defaults to false (read/write). ReadOnly here will force
     * the ReadOnly setting in VolumeMounts.
     * +optional
     * @type {boolean}
     * @memberof V1ScaleIOVolumeSource
     */
    readOnly?: boolean;
    /**
     * 
     * @type {V1LocalObjectReference}
     * @memberof V1ScaleIOVolumeSource
     */
    secretRef?: V1LocalObjectReference;
    /**
     * Flag to enable/disable SSL communication with Gateway, default false
     * +optional
     * @type {boolean}
     * @memberof V1ScaleIOVolumeSource
     */
    sslEnabled?: boolean;
    /**
     * Indicates whether the storage for a volume should be ThickProvisioned or ThinProvisioned.
     * Default is ThinProvisioned.
     * +optional
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    storageMode?: string;
    /**
     * The ScaleIO Storage Pool associated with the protection domain.
     * +optional
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    storagePool?: string;
    /**
     * The name of the storage system as configured in ScaleIO.
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    system?: string;
    /**
     * The name of a volume already created in the ScaleIO system
     * that is associated with this volume source.
     * @type {string}
     * @memberof V1ScaleIOVolumeSource
     */
    volumeName?: string;
}

export function V1ScaleIOVolumeSourceFromJSON(json: any): V1ScaleIOVolumeSource {
    return V1ScaleIOVolumeSourceFromJSONTyped(json, false);
}

export function V1ScaleIOVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ScaleIOVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fsType': !exists(json, 'fsType') ? undefined : json['fsType'],
        'gateway': !exists(json, 'gateway') ? undefined : json['gateway'],
        'protectionDomain': !exists(json, 'protectionDomain') ? undefined : json['protectionDomain'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'secretRef': !exists(json, 'secretRef') ? undefined : V1LocalObjectReferenceFromJSON(json['secretRef']),
        'sslEnabled': !exists(json, 'sslEnabled') ? undefined : json['sslEnabled'],
        'storageMode': !exists(json, 'storageMode') ? undefined : json['storageMode'],
        'storagePool': !exists(json, 'storagePool') ? undefined : json['storagePool'],
        'system': !exists(json, 'system') ? undefined : json['system'],
        'volumeName': !exists(json, 'volumeName') ? undefined : json['volumeName'],
    };
}

export function V1ScaleIOVolumeSourceToJSON(value?: V1ScaleIOVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fsType': value.fsType,
        'gateway': value.gateway,
        'protectionDomain': value.protectionDomain,
        'readOnly': value.readOnly,
        'secretRef': V1LocalObjectReferenceToJSON(value.secretRef),
        'sslEnabled': value.sslEnabled,
        'storageMode': value.storageMode,
        'storagePool': value.storagePool,
        'system': value.system,
        'volumeName': value.volumeName,
    };
}

