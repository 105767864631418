/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1EnvVarSource,
    V1EnvVarSourceFromJSON,
    V1EnvVarSourceFromJSONTyped,
    V1EnvVarSourceToJSON,
} from './V1EnvVarSource';

/**
 * 
 * @export
 * @interface V1EnvVar
 */
export interface V1EnvVar {
    /**
     * Name of the environment variable. Must be a C_IDENTIFIER.
     * @type {string}
     * @memberof V1EnvVar
     */
    name?: string;
    /**
     * Variable references $(VAR_NAME) are expanded
     * using the previously defined environment variables in the container and
     * any service environment variables. If a variable cannot be resolved,
     * the reference in the input string will be unchanged. Double $$ are reduced
     * to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e.
     * "$$(VAR_NAME)" will produce the string literal "$(VAR_NAME)".
     * Escaped references will never be expanded, regardless of whether the variable
     * exists or not.
     * Defaults to "".
     * +optional
     * @type {string}
     * @memberof V1EnvVar
     */
    value?: string;
    /**
     * 
     * @type {V1EnvVarSource}
     * @memberof V1EnvVar
     */
    valueFrom?: V1EnvVarSource;
}

export function V1EnvVarFromJSON(json: any): V1EnvVar {
    return V1EnvVarFromJSONTyped(json, false);
}

export function V1EnvVarFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1EnvVar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueFrom': !exists(json, 'valueFrom') ? undefined : V1EnvVarSourceFromJSON(json['valueFrom']),
    };
}

export function V1EnvVarToJSON(value?: V1EnvVar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'valueFrom': V1EnvVarSourceToJSON(value.valueFrom),
    };
}

