/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiBatchQueueCreateResponse,
    ApiBatchQueueCreateResponseFromJSON,
    ApiBatchQueueCreateResponseToJSON,
    ApiBatchQueueUpdateResponse,
    ApiBatchQueueUpdateResponseFromJSON,
    ApiBatchQueueUpdateResponseToJSON,
    ApiCancellationResult,
    ApiCancellationResultFromJSON,
    ApiCancellationResultToJSON,
    ApiJobCancelRequest,
    ApiJobCancelRequestFromJSON,
    ApiJobCancelRequestToJSON,
    ApiJobReprioritizeRequest,
    ApiJobReprioritizeRequestFromJSON,
    ApiJobReprioritizeRequestToJSON,
    ApiJobReprioritizeResponse,
    ApiJobReprioritizeResponseFromJSON,
    ApiJobReprioritizeResponseToJSON,
    ApiJobSetCancelRequest,
    ApiJobSetCancelRequestFromJSON,
    ApiJobSetCancelRequestToJSON,
    ApiJobSubmitRequest,
    ApiJobSubmitRequestFromJSON,
    ApiJobSubmitRequestToJSON,
    ApiJobSubmitResponse,
    ApiJobSubmitResponseFromJSON,
    ApiJobSubmitResponseToJSON,
    ApiQueue,
    ApiQueueFromJSON,
    ApiQueueToJSON,
    ApiQueueInfo,
    ApiQueueInfoFromJSON,
    ApiQueueInfoToJSON,
    ApiQueueList,
    ApiQueueListFromJSON,
    ApiQueueListToJSON,
    RuntimeError,
    RuntimeErrorFromJSON,
    RuntimeErrorToJSON,
} from '../models';

export interface CancelJobSetRequest {
    body: ApiJobSetCancelRequest;
}

export interface CancelJobsRequest {
    body: ApiJobCancelRequest;
}

export interface CreateQueueRequest {
    body: ApiQueue;
}

export interface CreateQueuesRequest {
    body: ApiQueueList;
}

export interface DeleteQueueRequest {
    name: string;
}

export interface GetQueueRequest {
    name: string;
}

export interface GetQueueInfoRequest {
    name: string;
}

export interface ReprioritizeJobsRequest {
    body: ApiJobReprioritizeRequest;
}

export interface SubmitJobsRequest {
    body: ApiJobSubmitRequest;
}

export interface UpdateQueueRequest {
    name: string;
    body: ApiQueue;
}

export interface UpdateQueuesRequest {
    body: ApiQueueList;
}

/**
 * 
 */
export class SubmitApi extends runtime.BaseAPI {

    /**
     */
    async cancelJobSetRaw(requestParameters: CancelJobSetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling cancelJobSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/jobset/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiJobSetCancelRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async cancelJobSet(requestParameters: CancelJobSetRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.cancelJobSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cancelJobsRaw(requestParameters: CancelJobsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCancellationResult>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling cancelJobs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/job/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiJobCancelRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCancellationResultFromJSON(jsonValue));
    }

    /**
     */
    async cancelJobs(requestParameters: CancelJobsRequest, initOverrides?: RequestInit): Promise<ApiCancellationResult> {
        const response = await this.cancelJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createQueueRaw(requestParameters: CreateQueueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createQueue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/queue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiQueueToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async createQueue(requestParameters: CreateQueueRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.createQueueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createQueuesRaw(requestParameters: CreateQueuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiBatchQueueCreateResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createQueues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/batched/create_queues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiQueueListToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiBatchQueueCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async createQueues(requestParameters: CreateQueuesRequest, initOverrides?: RequestInit): Promise<ApiBatchQueueCreateResponse> {
        const response = await this.createQueuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteQueueRaw(requestParameters: DeleteQueueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling deleteQueue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/queue/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteQueue(requestParameters: DeleteQueueRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.deleteQueueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQueueRaw(requestParameters: GetQueueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiQueue>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getQueue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/queue/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiQueueFromJSON(jsonValue));
    }

    /**
     */
    async getQueue(requestParameters: GetQueueRequest, initOverrides?: RequestInit): Promise<ApiQueue> {
        const response = await this.getQueueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQueueInfoRaw(requestParameters: GetQueueInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiQueueInfo>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getQueueInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/queue/{name}/info`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiQueueInfoFromJSON(jsonValue));
    }

    /**
     */
    async getQueueInfo(requestParameters: GetQueueInfoRequest, initOverrides?: RequestInit): Promise<ApiQueueInfo> {
        const response = await this.getQueueInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reprioritizeJobsRaw(requestParameters: ReprioritizeJobsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiJobReprioritizeResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling reprioritizeJobs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/job/reprioritize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiJobReprioritizeRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiJobReprioritizeResponseFromJSON(jsonValue));
    }

    /**
     */
    async reprioritizeJobs(requestParameters: ReprioritizeJobsRequest, initOverrides?: RequestInit): Promise<ApiJobReprioritizeResponse> {
        const response = await this.reprioritizeJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async submitJobsRaw(requestParameters: SubmitJobsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiJobSubmitResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling submitJobs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/job/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiJobSubmitRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiJobSubmitResponseFromJSON(jsonValue));
    }

    /**
     */
    async submitJobs(requestParameters: SubmitJobsRequest, initOverrides?: RequestInit): Promise<ApiJobSubmitResponse> {
        const response = await this.submitJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateQueueRaw(requestParameters: UpdateQueueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling updateQueue.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateQueue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/queue/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiQueueToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async updateQueue(requestParameters: UpdateQueueRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.updateQueueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateQueuesRaw(requestParameters: UpdateQueuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiBatchQueueUpdateResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateQueues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/batched/update_queues`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiQueueListToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiBatchQueueUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateQueues(requestParameters: UpdateQueuesRequest, initOverrides?: RequestInit): Promise<ApiBatchQueueUpdateResponse> {
        const response = await this.updateQueuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
