/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1Handler,
    V1HandlerFromJSON,
    V1HandlerFromJSONTyped,
    V1HandlerToJSON,
} from './V1Handler';

/**
 * Lifecycle describes actions that the management system should take in response to container lifecycle
 * events. For the PostStart and PreStop lifecycle handlers, management of the container blocks
 * until the action is complete, unless the container process fails, in which case the handler is aborted.
 * @export
 * @interface V1Lifecycle
 */
export interface V1Lifecycle {
    /**
     * 
     * @type {V1Handler}
     * @memberof V1Lifecycle
     */
    postStart?: V1Handler;
    /**
     * 
     * @type {V1Handler}
     * @memberof V1Lifecycle
     */
    preStop?: V1Handler;
}

export function V1LifecycleFromJSON(json: any): V1Lifecycle {
    return V1LifecycleFromJSONTyped(json, false);
}

export function V1LifecycleFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Lifecycle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postStart': !exists(json, 'postStart') ? undefined : V1HandlerFromJSON(json['postStart']),
        'preStop': !exists(json, 'preStop') ? undefined : V1HandlerFromJSON(json['preStop']),
    };
}

export function V1LifecycleToJSON(value?: V1Lifecycle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postStart': V1HandlerToJSON(value.postStart),
        'preStop': V1HandlerToJSON(value.preStop),
    };
}

