/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntstrIntOrString,
    IntstrIntOrStringFromJSON,
    IntstrIntOrStringFromJSONTyped,
    IntstrIntOrStringToJSON,
} from './IntstrIntOrString';

/**
 * ServicePort contains information on service's port.
 * @export
 * @interface V1ServicePort
 */
export interface V1ServicePort {
    /**
     * 
     * @type {string}
     * @memberof V1ServicePort
     */
    appProtocol?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicePort
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof V1ServicePort
     */
    nodePort?: number;
    /**
     * The port that will be exposed by this service.
     * @type {number}
     * @memberof V1ServicePort
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ServicePort
     */
    protocol?: string;
    /**
     * 
     * @type {IntstrIntOrString}
     * @memberof V1ServicePort
     */
    targetPort?: IntstrIntOrString;
}

export function V1ServicePortFromJSON(json: any): V1ServicePort {
    return V1ServicePortFromJSONTyped(json, false);
}

export function V1ServicePortFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ServicePort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appProtocol': !exists(json, 'appProtocol') ? undefined : json['appProtocol'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nodePort': !exists(json, 'nodePort') ? undefined : json['nodePort'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'targetPort': !exists(json, 'targetPort') ? undefined : IntstrIntOrStringFromJSON(json['targetPort']),
    };
}

export function V1ServicePortToJSON(value?: V1ServicePort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appProtocol': value.appProtocol,
        'name': value.name,
        'nodePort': value.nodePort,
        'port': value.port,
        'protocol': value.protocol,
        'targetPort': IntstrIntOrStringToJSON(value.targetPort),
    };
}

