/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * IngressTLS describes the transport layer security associated with an Ingress.
 * @export
 * @interface V1IngressTLS
 */
export interface V1IngressTLS {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1IngressTLS
     */
    hosts?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V1IngressTLS
     */
    secretName?: string;
}

export function V1IngressTLSFromJSON(json: any): V1IngressTLS {
    return V1IngressTLSFromJSONTyped(json, false);
}

export function V1IngressTLSFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1IngressTLS {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hosts': !exists(json, 'hosts') ? undefined : json['hosts'],
        'secretName': !exists(json, 'secretName') ? undefined : json['secretName'],
    };
}

export function V1IngressTLSToJSON(value?: V1IngressTLS | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hosts': value.hosts,
        'secretName': value.secretName,
    };
}

