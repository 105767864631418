/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * HTTPHeader describes a custom header to be used in HTTP probes
 * @export
 * @interface V1HTTPHeader
 */
export interface V1HTTPHeader {
    /**
     * The header field name
     * @type {string}
     * @memberof V1HTTPHeader
     */
    name?: string;
    /**
     * The header field value
     * @type {string}
     * @memberof V1HTTPHeader
     */
    value?: string;
}

export function V1HTTPHeaderFromJSON(json: any): V1HTTPHeader {
    return V1HTTPHeaderFromJSONTyped(json, false);
}

export function V1HTTPHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1HTTPHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function V1HTTPHeaderToJSON(value?: V1HTTPHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
    };
}

