/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LookoutGetJobSetsRequest,
    LookoutGetJobSetsRequestFromJSON,
    LookoutGetJobSetsRequestToJSON,
    LookoutGetJobSetsResponse,
    LookoutGetJobSetsResponseFromJSON,
    LookoutGetJobSetsResponseToJSON,
    LookoutGetJobsRequest,
    LookoutGetJobsRequestFromJSON,
    LookoutGetJobsRequestToJSON,
    LookoutGetJobsResponse,
    LookoutGetJobsResponseFromJSON,
    LookoutGetJobsResponseToJSON,
    LookoutSystemOverview,
    LookoutSystemOverviewFromJSON,
    LookoutSystemOverviewToJSON,
    RuntimeError,
    RuntimeErrorFromJSON,
    RuntimeErrorToJSON,
} from '../models';

export interface GetJobSetsRequest {
    body: LookoutGetJobSetsRequest;
}

export interface GetJobsRequest {
    body: LookoutGetJobsRequest;
}

/**
 * 
 */
export class LookoutApi extends runtime.BaseAPI {

    /**
     */
    async getJobSetsRaw(requestParameters: GetJobSetsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LookoutGetJobSetsResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getJobSets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/lookout/jobsets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LookoutGetJobSetsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookoutGetJobSetsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getJobSets(requestParameters: GetJobSetsRequest, initOverrides?: RequestInit): Promise<LookoutGetJobSetsResponse> {
        const response = await this.getJobSetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobsRaw(requestParameters: GetJobsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LookoutGetJobsResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getJobs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/lookout/jobs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LookoutGetJobsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookoutGetJobsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getJobs(requestParameters: GetJobsRequest, initOverrides?: RequestInit): Promise<LookoutGetJobsResponse> {
        const response = await this.getJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async overviewRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LookoutSystemOverview>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/lookout/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookoutSystemOverviewFromJSON(jsonValue));
    }

    /**
     */
    async overview(initOverrides?: RequestInit): Promise<LookoutSystemOverview> {
        const response = await this.overviewRaw(initOverrides);
        return await response.value();
    }

}
