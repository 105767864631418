/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * // other fields
 * }
 * @export
 * @interface V1Condition
 */
export interface V1Condition {
    /**
     * Programs using times should typically store and pass them as values,
     * not pointers. That is, time variables and struct fields should be of
     * type time.Time, not *time.Time.
     * 
     * A Time value can be used by multiple goroutines simultaneously except
     * that the methods GobDecode, UnmarshalBinary, UnmarshalJSON and
     * UnmarshalText are not concurrency-safe.
     * 
     * Time instants can be compared using the Before, After, and Equal methods.
     * The Sub method subtracts two instants, producing a Duration.
     * The Add method adds a Time and a Duration, producing a Time.
     * 
     * The zero value of type Time is January 1, year 1, 00:00:00.000000000 UTC.
     * As this time is unlikely to come up in practice, the IsZero method gives
     * a simple way of detecting a time that has not been initialized explicitly.
     * 
     * Each Time has associated with it a Location, consulted when computing the
     * presentation form of the time, such as in the Format, Hour, and Year methods.
     * The methods Local, UTC, and In return a Time with a specific location.
     * Changing the location in this way changes only the presentation; it does not
     * change the instant in time being denoted and therefore does not affect the
     * computations described in earlier paragraphs.
     * 
     * Representations of a Time value saved by the GobEncode, MarshalBinary,
     * MarshalJSON, and MarshalText methods store the Time.Location's offset, but not
     * the location name. They therefore lose information about Daylight Saving Time.
     * 
     * In addition to the required “wall clock” reading, a Time may contain an optional
     * reading of the current process's monotonic clock, to provide additional precision
     * for comparison or subtraction.
     * See the “Monotonic Clocks” section in the package documentation for details.
     * 
     * Note that the Go == operator compares not just the time instant but also the
     * Location and the monotonic clock reading. Therefore, Time values should not
     * be used as map or database keys without first guaranteeing that the
     * identical Location has been set for all values, which can be achieved
     * through use of the UTC or Local method, and that the monotonic clock reading
     * has been stripped by setting t = t.Round(0). In general, prefer t.Equal(u)
     * to t == u, since t.Equal uses the most accurate comparison available and
     * correctly handles the case when only one of its arguments has a monotonic
     * clock reading.
     * @type {Date}
     * @memberof V1Condition
     */
    lastTransitionTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof V1Condition
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Condition
     */
    observedGeneration?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Condition
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Condition
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Condition
     */
    type?: string;
}

export function V1ConditionFromJSON(json: any): V1Condition {
    return V1ConditionFromJSONTyped(json, false);
}

export function V1ConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Condition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastTransitionTime': !exists(json, 'lastTransitionTime') ? undefined : (new Date(json['lastTransitionTime'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'observedGeneration': !exists(json, 'observedGeneration') ? undefined : json['observedGeneration'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function V1ConditionToJSON(value?: V1Condition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastTransitionTime': value.lastTransitionTime === undefined ? undefined : (value.lastTransitionTime.toISOString()),
        'message': value.message,
        'observedGeneration': value.observedGeneration,
        'reason': value.reason,
        'status': value.status,
        'type': value.type,
    };
}

