/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiIngressConfig,
    ApiIngressConfigFromJSON,
    ApiIngressConfigFromJSONTyped,
    ApiIngressConfigToJSON,
} from './ApiIngressConfig';
import {
    ApiServiceConfig,
    ApiServiceConfigFromJSON,
    ApiServiceConfigFromJSONTyped,
    ApiServiceConfigToJSON,
} from './ApiServiceConfig';
import {
    V1Ingress,
    V1IngressFromJSON,
    V1IngressFromJSONTyped,
    V1IngressToJSON,
} from './V1Ingress';
import {
    V1PodSpec,
    V1PodSpecFromJSON,
    V1PodSpecFromJSONTyped,
    V1PodSpecToJSON,
} from './V1PodSpec';
import {
    V1ResourceRequirements,
    V1ResourceRequirementsFromJSON,
    V1ResourceRequirementsFromJSONTyped,
    V1ResourceRequirementsToJSON,
} from './V1ResourceRequirements';
import {
    V1Service,
    V1ServiceFromJSON,
    V1ServiceFromJSONTyped,
    V1ServiceToJSON,
} from './V1Service';

/**
 * 
 * @export
 * @interface ApiJob
 */
export interface ApiJob {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiJob
     */
    annotations?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    compressedQueueOwnershipUserGroups?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiJob
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    id?: string;
    /**
     * Services can be provided either as Armada-specific config objects or as proper k8s objects.
     * These options are exclusive, i.e., if either ingress or services is provided,
     * then neither of k8s_ingress or k8s_service can be provided, and vice versa.
     * @type {Array<ApiIngressConfig>}
     * @memberof ApiJob
     */
    ingress?: Array<ApiIngressConfig>;
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    jobSetId?: string;
    /**
     * 
     * @type {Array<V1Ingress>}
     * @memberof ApiJob
     */
    k8sIngress?: Array<V1Ingress>;
    /**
     * 
     * @type {Array<V1Service>}
     * @memberof ApiJob
     */
    k8sService?: Array<V1Service>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiJob
     */
    labels?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    owner?: string;
    /**
     * 
     * @type {V1PodSpec}
     * @memberof ApiJob
     */
    podSpec?: V1PodSpec;
    /**
     * 
     * @type {Array<V1PodSpec>}
     * @memberof ApiJob
     */
    podSpecs?: Array<V1PodSpec>;
    /**
     * 
     * @type {number}
     * @memberof ApiJob
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiJob
     */
    queue?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiJob
     */
    queueOwnershipUserGroups?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiJob
     */
    requiredNodeLabels?: { [key: string]: string; };
    /**
     * Indicates which scheduler should manage this job.
     * If empty, the default scheduler is used.
     * @type {string}
     * @memberof ApiJob
     */
    scheduler?: string;
    /**
     * 
     * @type {V1ResourceRequirements}
     * @memberof ApiJob
     */
    schedulingResourceRequirements?: V1ResourceRequirements;
    /**
     * 
     * @type {Array<ApiServiceConfig>}
     * @memberof ApiJob
     */
    services?: Array<ApiServiceConfig>;
}

export function ApiJobFromJSON(json: any): ApiJob {
    return ApiJobFromJSONTyped(json, false);
}

export function ApiJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'compressedQueueOwnershipUserGroups': !exists(json, 'compressedQueueOwnershipUserGroups') ? undefined : json['compressedQueueOwnershipUserGroups'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ingress': !exists(json, 'ingress') ? undefined : ((json['ingress'] as Array<any>).map(ApiIngressConfigFromJSON)),
        'jobSetId': !exists(json, 'jobSetId') ? undefined : json['jobSetId'],
        'k8sIngress': !exists(json, 'k8sIngress') ? undefined : ((json['k8sIngress'] as Array<any>).map(V1IngressFromJSON)),
        'k8sService': !exists(json, 'k8sService') ? undefined : ((json['k8sService'] as Array<any>).map(V1ServiceFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'podSpec': !exists(json, 'podSpec') ? undefined : V1PodSpecFromJSON(json['podSpec']),
        'podSpecs': !exists(json, 'podSpecs') ? undefined : ((json['podSpecs'] as Array<any>).map(V1PodSpecFromJSON)),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'queueOwnershipUserGroups': !exists(json, 'queueOwnershipUserGroups') ? undefined : json['queueOwnershipUserGroups'],
        'requiredNodeLabels': !exists(json, 'requiredNodeLabels') ? undefined : json['requiredNodeLabels'],
        'scheduler': !exists(json, 'scheduler') ? undefined : json['scheduler'],
        'schedulingResourceRequirements': !exists(json, 'schedulingResourceRequirements') ? undefined : V1ResourceRequirementsFromJSON(json['schedulingResourceRequirements']),
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(ApiServiceConfigFromJSON)),
    };
}

export function ApiJobToJSON(value?: ApiJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'clientId': value.clientId,
        'compressedQueueOwnershipUserGroups': value.compressedQueueOwnershipUserGroups,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'id': value.id,
        'ingress': value.ingress === undefined ? undefined : ((value.ingress as Array<any>).map(ApiIngressConfigToJSON)),
        'jobSetId': value.jobSetId,
        'k8sIngress': value.k8sIngress === undefined ? undefined : ((value.k8sIngress as Array<any>).map(V1IngressToJSON)),
        'k8sService': value.k8sService === undefined ? undefined : ((value.k8sService as Array<any>).map(V1ServiceToJSON)),
        'labels': value.labels,
        'namespace': value.namespace,
        'owner': value.owner,
        'podSpec': V1PodSpecToJSON(value.podSpec),
        'podSpecs': value.podSpecs === undefined ? undefined : ((value.podSpecs as Array<any>).map(V1PodSpecToJSON)),
        'priority': value.priority,
        'queue': value.queue,
        'queueOwnershipUserGroups': value.queueOwnershipUserGroups,
        'requiredNodeLabels': value.requiredNodeLabels,
        'scheduler': value.scheduler,
        'schedulingResourceRequirements': V1ResourceRequirementsToJSON(value.schedulingResourceRequirements),
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(ApiServiceConfigToJSON)),
    };
}

