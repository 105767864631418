/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1IngressServiceBackend,
    V1IngressServiceBackendFromJSON,
    V1IngressServiceBackendFromJSONTyped,
    V1IngressServiceBackendToJSON,
} from './V1IngressServiceBackend';
import {
    V1TypedLocalObjectReference,
    V1TypedLocalObjectReferenceFromJSON,
    V1TypedLocalObjectReferenceFromJSONTyped,
    V1TypedLocalObjectReferenceToJSON,
} from './V1TypedLocalObjectReference';

/**
 * IngressBackend describes all endpoints for a given service and port.
 * @export
 * @interface V1IngressBackend
 */
export interface V1IngressBackend {
    /**
     * 
     * @type {V1TypedLocalObjectReference}
     * @memberof V1IngressBackend
     */
    resource?: V1TypedLocalObjectReference;
    /**
     * 
     * @type {V1IngressServiceBackend}
     * @memberof V1IngressBackend
     */
    service?: V1IngressServiceBackend;
}

export function V1IngressBackendFromJSON(json: any): V1IngressBackend {
    return V1IngressBackendFromJSONTyped(json, false);
}

export function V1IngressBackendFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1IngressBackend {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resource': !exists(json, 'resource') ? undefined : V1TypedLocalObjectReferenceFromJSON(json['resource']),
        'service': !exists(json, 'service') ? undefined : V1IngressServiceBackendFromJSON(json['service']),
    };
}

export function V1IngressBackendToJSON(value?: V1IngressBackend | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resource': V1TypedLocalObjectReferenceToJSON(value.resource),
        'service': V1IngressServiceBackendToJSON(value.service),
    };
}

