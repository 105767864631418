/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1LocalObjectReference,
    V1LocalObjectReferenceFromJSON,
    V1LocalObjectReferenceFromJSONTyped,
    V1LocalObjectReferenceToJSON,
} from './V1LocalObjectReference';

/**
 * Represents a Ceph Filesystem mount that lasts the lifetime of a pod
 * Cephfs volumes do not support ownership management or SELinux relabeling.
 * @export
 * @interface V1CephFSVolumeSource
 */
export interface V1CephFSVolumeSource {
    /**
     * Required: Monitors is a collection of Ceph monitors
     * More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
     * @type {Array<string>}
     * @memberof V1CephFSVolumeSource
     */
    monitors?: Array<string>;
    /**
     * Optional: Used as the mounted root, rather than the full Ceph tree, default is /
     * +optional
     * @type {string}
     * @memberof V1CephFSVolumeSource
     */
    path?: string;
    /**
     * Optional: Defaults to false (read/write). ReadOnly here will force
     * the ReadOnly setting in VolumeMounts.
     * More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
     * +optional
     * @type {boolean}
     * @memberof V1CephFSVolumeSource
     */
    readOnly?: boolean;
    /**
     * Optional: SecretFile is the path to key ring for User, default is /etc/ceph/user.secret
     * More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
     * +optional
     * @type {string}
     * @memberof V1CephFSVolumeSource
     */
    secretFile?: string;
    /**
     * 
     * @type {V1LocalObjectReference}
     * @memberof V1CephFSVolumeSource
     */
    secretRef?: V1LocalObjectReference;
    /**
     * Optional: User is the rados user name, default is admin
     * More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
     * +optional
     * @type {string}
     * @memberof V1CephFSVolumeSource
     */
    user?: string;
}

export function V1CephFSVolumeSourceFromJSON(json: any): V1CephFSVolumeSource {
    return V1CephFSVolumeSourceFromJSONTyped(json, false);
}

export function V1CephFSVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CephFSVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitors': !exists(json, 'monitors') ? undefined : json['monitors'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'secretFile': !exists(json, 'secretFile') ? undefined : json['secretFile'],
        'secretRef': !exists(json, 'secretRef') ? undefined : V1LocalObjectReferenceFromJSON(json['secretRef']),
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function V1CephFSVolumeSourceToJSON(value?: V1CephFSVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitors': value.monitors,
        'path': value.path,
        'readOnly': value.readOnly,
        'secretFile': value.secretFile,
        'secretRef': V1LocalObjectReferenceToJSON(value.secretRef),
        'user': value.user,
    };
}

