/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Glusterfs volumes do not support ownership management or SELinux relabeling.
 * @export
 * @interface V1GlusterfsVolumeSource
 */
export interface V1GlusterfsVolumeSource {
    /**
     * EndpointsName is the endpoint name that details Glusterfs topology.
     * More info: https://examples.k8s.io/volumes/glusterfs/README.md#create-a-pod
     * @type {string}
     * @memberof V1GlusterfsVolumeSource
     */
    endpoints?: string;
    /**
     * Path is the Glusterfs volume path.
     * More info: https://examples.k8s.io/volumes/glusterfs/README.md#create-a-pod
     * @type {string}
     * @memberof V1GlusterfsVolumeSource
     */
    path?: string;
    /**
     * ReadOnly here will force the Glusterfs volume to be mounted with read-only permissions.
     * Defaults to false.
     * More info: https://examples.k8s.io/volumes/glusterfs/README.md#create-a-pod
     * +optional
     * @type {boolean}
     * @memberof V1GlusterfsVolumeSource
     */
    readOnly?: boolean;
}

export function V1GlusterfsVolumeSourceFromJSON(json: any): V1GlusterfsVolumeSource {
    return V1GlusterfsVolumeSourceFromJSONTyped(json, false);
}

export function V1GlusterfsVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GlusterfsVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoints': !exists(json, 'endpoints') ? undefined : json['endpoints'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
    };
}

export function V1GlusterfsVolumeSourceToJSON(value?: V1GlusterfsVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoints': value.endpoints,
        'path': value.path,
        'readOnly': value.readOnly,
    };
}

