/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Quobyte volumes do not support ownership management or SELinux relabeling.
 * @export
 * @interface V1QuobyteVolumeSource
 */
export interface V1QuobyteVolumeSource {
    /**
     * Group to map volume access to
     * Default is no group
     * +optional
     * @type {string}
     * @memberof V1QuobyteVolumeSource
     */
    group?: string;
    /**
     * ReadOnly here will force the Quobyte volume to be mounted with read-only permissions.
     * Defaults to false.
     * +optional
     * @type {boolean}
     * @memberof V1QuobyteVolumeSource
     */
    readOnly?: boolean;
    /**
     * Registry represents a single or multiple Quobyte Registry services
     * specified as a string as host:port pair (multiple entries are separated with commas)
     * which acts as the central registry for volumes
     * @type {string}
     * @memberof V1QuobyteVolumeSource
     */
    registry?: string;
    /**
     * Tenant owning the given Quobyte volume in the Backend
     * Used with dynamically provisioned Quobyte volumes, value is set by the plugin
     * +optional
     * @type {string}
     * @memberof V1QuobyteVolumeSource
     */
    tenant?: string;
    /**
     * User to map volume access to
     * Defaults to serivceaccount user
     * +optional
     * @type {string}
     * @memberof V1QuobyteVolumeSource
     */
    user?: string;
    /**
     * Volume is a string that references an already created Quobyte volume by name.
     * @type {string}
     * @memberof V1QuobyteVolumeSource
     */
    volume?: string;
}

export function V1QuobyteVolumeSourceFromJSON(json: any): V1QuobyteVolumeSource {
    return V1QuobyteVolumeSourceFromJSONTyped(json, false);
}

export function V1QuobyteVolumeSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1QuobyteVolumeSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group': !exists(json, 'group') ? undefined : json['group'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'registry': !exists(json, 'registry') ? undefined : json['registry'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
    };
}

export function V1QuobyteVolumeSourceToJSON(value?: V1QuobyteVolumeSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group': value.group,
        'readOnly': value.readOnly,
        'registry': value.registry,
        'tenant': value.tenant,
        'user': value.user,
        'volume': value.volume,
    };
}

