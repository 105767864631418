/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A label selector requirement is a selector that contains values, a key, and an operator that
 * relates the key and values.
 * @export
 * @interface V1LabelSelectorRequirement
 */
export interface V1LabelSelectorRequirement {
    /**
     * key is the label key that the selector applies to.
     * +patchMergeKey=key
     * +patchStrategy=merge
     * @type {string}
     * @memberof V1LabelSelectorRequirement
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof V1LabelSelectorRequirement
     */
    operator?: string;
    /**
     * values is an array of string values. If the operator is In or NotIn,
     * the values array must be non-empty. If the operator is Exists or DoesNotExist,
     * the values array must be empty. This array is replaced during a strategic
     * merge patch.
     * +optional
     * @type {Array<string>}
     * @memberof V1LabelSelectorRequirement
     */
    values?: Array<string>;
}

export function V1LabelSelectorRequirementFromJSON(json: any): V1LabelSelectorRequirement {
    return V1LabelSelectorRequirementFromJSONTyped(json, false);
}

export function V1LabelSelectorRequirementFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LabelSelectorRequirement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'operator': !exists(json, 'operator') ? undefined : json['operator'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function V1LabelSelectorRequirementToJSON(value?: V1LabelSelectorRequirement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'operator': value.operator,
        'values': value.values,
    };
}

