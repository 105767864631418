/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiCancellationResult
 */
export interface ApiCancellationResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiCancellationResult
     */
    cancelledIds?: Array<string>;
}

export function ApiCancellationResultFromJSON(json: any): ApiCancellationResult {
    return ApiCancellationResultFromJSONTyped(json, false);
}

export function ApiCancellationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCancellationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancelledIds': !exists(json, 'cancelledIds') ? undefined : json['cancelledIds'],
    };
}

export function ApiCancellationResultToJSON(value?: ApiCancellationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancelledIds': value.cancelledIds,
    };
}

