/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1PortStatus
 */
export interface V1PortStatus {
    /**
     * 
     * @type {string}
     * @memberof V1PortStatus
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof V1PortStatus
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof V1PortStatus
     */
    protocol?: string;
}

export function V1PortStatusFromJSON(json: any): V1PortStatus {
    return V1PortStatusFromJSONTyped(json, false);
}

export function V1PortStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PortStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
    };
}

export function V1PortStatusToJSON(value?: V1PortStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'port': value.port,
        'protocol': value.protocol,
    };
}

