/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ContainerPort,
    V1ContainerPortFromJSON,
    V1ContainerPortFromJSONTyped,
    V1ContainerPortToJSON,
} from './V1ContainerPort';
import {
    V1EnvFromSource,
    V1EnvFromSourceFromJSON,
    V1EnvFromSourceFromJSONTyped,
    V1EnvFromSourceToJSON,
} from './V1EnvFromSource';
import {
    V1EnvVar,
    V1EnvVarFromJSON,
    V1EnvVarFromJSONTyped,
    V1EnvVarToJSON,
} from './V1EnvVar';
import {
    V1Lifecycle,
    V1LifecycleFromJSON,
    V1LifecycleFromJSONTyped,
    V1LifecycleToJSON,
} from './V1Lifecycle';
import {
    V1Probe,
    V1ProbeFromJSON,
    V1ProbeFromJSONTyped,
    V1ProbeToJSON,
} from './V1Probe';
import {
    V1ResourceRequirements,
    V1ResourceRequirementsFromJSON,
    V1ResourceRequirementsFromJSONTyped,
    V1ResourceRequirementsToJSON,
} from './V1ResourceRequirements';
import {
    V1SecurityContext,
    V1SecurityContextFromJSON,
    V1SecurityContextFromJSONTyped,
    V1SecurityContextToJSON,
} from './V1SecurityContext';
import {
    V1VolumeDevice,
    V1VolumeDeviceFromJSON,
    V1VolumeDeviceFromJSONTyped,
    V1VolumeDeviceToJSON,
} from './V1VolumeDevice';
import {
    V1VolumeMount,
    V1VolumeMountFromJSON,
    V1VolumeMountFromJSONTyped,
    V1VolumeMountToJSON,
} from './V1VolumeMount';

/**
 * An EphemeralContainer is a container that may be added temporarily to an existing pod for
 * user-initiated activities such as debugging. Ephemeral containers have no resource or
 * scheduling guarantees, and they will not be restarted when they exit or when a pod is
 * removed or restarted. If an ephemeral container causes a pod to exceed its resource
 * allocation, the pod may be evicted.
 * Ephemeral containers may not be added by directly updating the pod spec. They must be added
 * via the pod's ephemeralcontainers subresource, and they will appear in the pod spec
 * once added.
 * This is an alpha feature enabled by the EphemeralContainers feature flag.
 * @export
 * @interface V1EphemeralContainer
 */
export interface V1EphemeralContainer {
    /**
     * Arguments to the entrypoint.
     * The docker image's CMD is used if this is not provided.
     * Variable references $(VAR_NAME) are expanded using the container's environment. If a variable
     * cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced
     * to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will
     * produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless
     * of whether the variable exists or not. Cannot be updated.
     * More info: https://kubernetes.io/docs/tasks/inject-data-application/define-command-argument-container/#running-a-command-in-a-shell
     * +optional
     * @type {Array<string>}
     * @memberof V1EphemeralContainer
     */
    args?: Array<string>;
    /**
     * Entrypoint array. Not executed within a shell.
     * The docker image's ENTRYPOINT is used if this is not provided.
     * Variable references $(VAR_NAME) are expanded using the container's environment. If a variable
     * cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced
     * to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will
     * produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless
     * of whether the variable exists or not. Cannot be updated.
     * More info: https://kubernetes.io/docs/tasks/inject-data-application/define-command-argument-container/#running-a-command-in-a-shell
     * +optional
     * @type {Array<string>}
     * @memberof V1EphemeralContainer
     */
    command?: Array<string>;
    /**
     * List of environment variables to set in the container.
     * Cannot be updated.
     * +optional
     * +patchMergeKey=name
     * +patchStrategy=merge
     * @type {Array<V1EnvVar>}
     * @memberof V1EphemeralContainer
     */
    env?: Array<V1EnvVar>;
    /**
     * List of sources to populate environment variables in the container.
     * The keys defined within a source must be a C_IDENTIFIER. All invalid keys
     * will be reported as an event when the container is starting. When a key exists in multiple
     * sources, the value associated with the last source will take precedence.
     * Values defined by an Env with a duplicate key will take precedence.
     * Cannot be updated.
     * +optional
     * @type {Array<V1EnvFromSource>}
     * @memberof V1EphemeralContainer
     */
    envFrom?: Array<V1EnvFromSource>;
    /**
     * Docker image name.
     * More info: https://kubernetes.io/docs/concepts/containers/images
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    image?: string;
    /**
     * PullPolicy describes a policy for if/when to pull a container image
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    imagePullPolicy?: string;
    /**
     * 
     * @type {V1Lifecycle}
     * @memberof V1EphemeralContainer
     */
    lifecycle?: V1Lifecycle;
    /**
     * 
     * @type {V1Probe}
     * @memberof V1EphemeralContainer
     */
    livenessProbe?: V1Probe;
    /**
     * Name of the ephemeral container specified as a DNS_LABEL.
     * This name must be unique among all containers, init containers and ephemeral containers.
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    name?: string;
    /**
     * Ports are not allowed for ephemeral containers.
     * @type {Array<V1ContainerPort>}
     * @memberof V1EphemeralContainer
     */
    ports?: Array<V1ContainerPort>;
    /**
     * 
     * @type {V1Probe}
     * @memberof V1EphemeralContainer
     */
    readinessProbe?: V1Probe;
    /**
     * 
     * @type {V1ResourceRequirements}
     * @memberof V1EphemeralContainer
     */
    resources?: V1ResourceRequirements;
    /**
     * 
     * @type {V1SecurityContext}
     * @memberof V1EphemeralContainer
     */
    securityContext?: V1SecurityContext;
    /**
     * 
     * @type {V1Probe}
     * @memberof V1EphemeralContainer
     */
    startupProbe?: V1Probe;
    /**
     * Whether this container should allocate a buffer for stdin in the container runtime. If this
     * is not set, reads from stdin in the container will always result in EOF.
     * Default is false.
     * +optional
     * @type {boolean}
     * @memberof V1EphemeralContainer
     */
    stdin?: boolean;
    /**
     * Whether the container runtime should close the stdin channel after it has been opened by
     * a single attach. When stdin is true the stdin stream will remain open across multiple attach
     * sessions. If stdinOnce is set to true, stdin is opened on container start, is empty until the
     * first client attaches to stdin, and then remains open and accepts data until the client disconnects,
     * at which time stdin is closed and remains closed until the container is restarted. If this
     * flag is false, a container processes that reads from stdin will never receive an EOF.
     * Default is false
     * +optional
     * @type {boolean}
     * @memberof V1EphemeralContainer
     */
    stdinOnce?: boolean;
    /**
     * If set, the name of the container from PodSpec that this ephemeral container targets.
     * The ephemeral container will be run in the namespaces (IPC, PID, etc) of this container.
     * If not set then the ephemeral container is run in whatever namespaces are shared
     * for the pod. Note that the container runtime must support this feature.
     * +optional
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    targetContainerName?: string;
    /**
     * Optional: Path at which the file to which the container's termination message
     * will be written is mounted into the container's filesystem.
     * Message written is intended to be brief final status, such as an assertion failure message.
     * Will be truncated by the node if greater than 4096 bytes. The total message length across
     * all containers will be limited to 12kb.
     * Defaults to /dev/termination-log.
     * Cannot be updated.
     * +optional
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    terminationMessagePath?: string;
    /**
     * 
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    terminationMessagePolicy?: string;
    /**
     * Whether this container should allocate a TTY for itself, also requires 'stdin' to be true.
     * Default is false.
     * +optional
     * @type {boolean}
     * @memberof V1EphemeralContainer
     */
    tty?: boolean;
    /**
     * volumeDevices is the list of block devices to be used by the container.
     * +patchMergeKey=devicePath
     * +patchStrategy=merge
     * +optional
     * @type {Array<V1VolumeDevice>}
     * @memberof V1EphemeralContainer
     */
    volumeDevices?: Array<V1VolumeDevice>;
    /**
     * Pod volumes to mount into the container's filesystem.
     * Cannot be updated.
     * +optional
     * +patchMergeKey=mountPath
     * +patchStrategy=merge
     * @type {Array<V1VolumeMount>}
     * @memberof V1EphemeralContainer
     */
    volumeMounts?: Array<V1VolumeMount>;
    /**
     * Container's working directory.
     * If not specified, the container runtime's default will be used, which
     * might be configured in the container image.
     * Cannot be updated.
     * +optional
     * @type {string}
     * @memberof V1EphemeralContainer
     */
    workingDir?: string;
}

export function V1EphemeralContainerFromJSON(json: any): V1EphemeralContainer {
    return V1EphemeralContainerFromJSONTyped(json, false);
}

export function V1EphemeralContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1EphemeralContainer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'args': !exists(json, 'args') ? undefined : json['args'],
        'command': !exists(json, 'command') ? undefined : json['command'],
        'env': !exists(json, 'env') ? undefined : ((json['env'] as Array<any>).map(V1EnvVarFromJSON)),
        'envFrom': !exists(json, 'envFrom') ? undefined : ((json['envFrom'] as Array<any>).map(V1EnvFromSourceFromJSON)),
        'image': !exists(json, 'image') ? undefined : json['image'],
        'imagePullPolicy': !exists(json, 'imagePullPolicy') ? undefined : json['imagePullPolicy'],
        'lifecycle': !exists(json, 'lifecycle') ? undefined : V1LifecycleFromJSON(json['lifecycle']),
        'livenessProbe': !exists(json, 'livenessProbe') ? undefined : V1ProbeFromJSON(json['livenessProbe']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ports': !exists(json, 'ports') ? undefined : ((json['ports'] as Array<any>).map(V1ContainerPortFromJSON)),
        'readinessProbe': !exists(json, 'readinessProbe') ? undefined : V1ProbeFromJSON(json['readinessProbe']),
        'resources': !exists(json, 'resources') ? undefined : V1ResourceRequirementsFromJSON(json['resources']),
        'securityContext': !exists(json, 'securityContext') ? undefined : V1SecurityContextFromJSON(json['securityContext']),
        'startupProbe': !exists(json, 'startupProbe') ? undefined : V1ProbeFromJSON(json['startupProbe']),
        'stdin': !exists(json, 'stdin') ? undefined : json['stdin'],
        'stdinOnce': !exists(json, 'stdinOnce') ? undefined : json['stdinOnce'],
        'targetContainerName': !exists(json, 'targetContainerName') ? undefined : json['targetContainerName'],
        'terminationMessagePath': !exists(json, 'terminationMessagePath') ? undefined : json['terminationMessagePath'],
        'terminationMessagePolicy': !exists(json, 'terminationMessagePolicy') ? undefined : json['terminationMessagePolicy'],
        'tty': !exists(json, 'tty') ? undefined : json['tty'],
        'volumeDevices': !exists(json, 'volumeDevices') ? undefined : ((json['volumeDevices'] as Array<any>).map(V1VolumeDeviceFromJSON)),
        'volumeMounts': !exists(json, 'volumeMounts') ? undefined : ((json['volumeMounts'] as Array<any>).map(V1VolumeMountFromJSON)),
        'workingDir': !exists(json, 'workingDir') ? undefined : json['workingDir'],
    };
}

export function V1EphemeralContainerToJSON(value?: V1EphemeralContainer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'args': value.args,
        'command': value.command,
        'env': value.env === undefined ? undefined : ((value.env as Array<any>).map(V1EnvVarToJSON)),
        'envFrom': value.envFrom === undefined ? undefined : ((value.envFrom as Array<any>).map(V1EnvFromSourceToJSON)),
        'image': value.image,
        'imagePullPolicy': value.imagePullPolicy,
        'lifecycle': V1LifecycleToJSON(value.lifecycle),
        'livenessProbe': V1ProbeToJSON(value.livenessProbe),
        'name': value.name,
        'ports': value.ports === undefined ? undefined : ((value.ports as Array<any>).map(V1ContainerPortToJSON)),
        'readinessProbe': V1ProbeToJSON(value.readinessProbe),
        'resources': V1ResourceRequirementsToJSON(value.resources),
        'securityContext': V1SecurityContextToJSON(value.securityContext),
        'startupProbe': V1ProbeToJSON(value.startupProbe),
        'stdin': value.stdin,
        'stdinOnce': value.stdinOnce,
        'targetContainerName': value.targetContainerName,
        'terminationMessagePath': value.terminationMessagePath,
        'terminationMessagePolicy': value.terminationMessagePolicy,
        'tty': value.tty,
        'volumeDevices': value.volumeDevices === undefined ? undefined : ((value.volumeDevices as Array<any>).map(V1VolumeDeviceToJSON)),
        'volumeMounts': value.volumeMounts === undefined ? undefined : ((value.volumeMounts as Array<any>).map(V1VolumeMountToJSON)),
        'workingDir': value.workingDir,
    };
}

