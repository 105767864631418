/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ServicePort,
    V1ServicePortFromJSON,
    V1ServicePortFromJSONTyped,
    V1ServicePortToJSON,
} from './V1ServicePort';
import {
    V1SessionAffinityConfig,
    V1SessionAffinityConfigFromJSON,
    V1SessionAffinityConfigFromJSONTyped,
    V1SessionAffinityConfigToJSON,
} from './V1SessionAffinityConfig';

/**
 * ServiceSpec describes the attributes that a user creates on a service.
 * @export
 * @interface V1ServiceSpec
 */
export interface V1ServiceSpec {
    /**
     * 
     * @type {boolean}
     * @memberof V1ServiceSpec
     */
    allocateLoadBalancerNodePorts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    clusterIP?: string;
    /**
     * ClusterIPs is a list of IP addresses assigned to this service, and are
     * usually assigned randomly.  If an address is specified manually, is
     * in-range (as per system configuration), and is not in use, it will be
     * allocated to the service; otherwise creation of the service will fail.
     * This field may not be changed through updates unless the type field is
     * also being changed to ExternalName (which requires this field to be
     * empty) or the type field is being changed from ExternalName (in which
     * case this field may optionally be specified, as describe above).  Valid
     * values are "None", empty string (""), or a valid IP address.  Setting
     * this to "None" makes a "headless service" (no virtual IP), which is
     * useful when direct endpoint connections are preferred and proxying is
     * not required.  Only applies to types ClusterIP, NodePort, and
     * LoadBalancer. If this field is specified when creating a Service of type
     * ExternalName, creation will fail. This field will be wiped when updating
     * a Service to type ExternalName.  If this field is not specified, it will
     * be initialized from the clusterIP field.  If this field is specified,
     * clients must ensure that clusterIPs[0] and clusterIP have the same
     * value.
     * 
     * Unless the "IPv6DualStack" feature gate is enabled, this field is
     * limited to one value, which must be the same as the clusterIP field.  If
     * the feature gate is enabled, this field may hold a maximum of two
     * entries (dual-stack IPs, in either order).  These IPs must correspond to
     * the values of the ipFamilies field. Both clusterIPs and ipFamilies are
     * governed by the ipFamilyPolicy field.
     * More info: https://kubernetes.io/docs/concepts/services-networking/service/#virtual-ips-and-service-proxies
     * +listType=atomic
     * +optional
     * @type {Array<string>}
     * @memberof V1ServiceSpec
     */
    clusterIPs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ServiceSpec
     */
    externalIPs?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    externalName?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    externalTrafficPolicy?: string;
    /**
     * 
     * @type {number}
     * @memberof V1ServiceSpec
     */
    healthCheckNodePort?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    internalTrafficPolicy?: string;
    /**
     * IPFamilies is a list of IP families (e.g. IPv4, IPv6) assigned to this
     * service, and is gated by the "IPv6DualStack" feature gate.  This field
     * is usually assigned automatically based on cluster configuration and the
     * ipFamilyPolicy field. If this field is specified manually, the requested
     * family is available in the cluster, and ipFamilyPolicy allows it, it
     * will be used; otherwise creation of the service will fail.  This field
     * is conditionally mutable: it allows for adding or removing a secondary
     * IP family, but it does not allow changing the primary IP family of the
     * Service.  Valid values are "IPv4" and "IPv6".  This field only applies
     * to Services of types ClusterIP, NodePort, and LoadBalancer, and does
     * apply to "headless" services.  This field will be wiped when updating a
     * Service to type ExternalName.
     * 
     * This field may hold a maximum of two entries (dual-stack families, in
     * either order).  These families must correspond to the values of the
     * clusterIPs field, if specified. Both clusterIPs and ipFamilies are
     * governed by the ipFamilyPolicy field.
     * +listType=atomic
     * +optional
     * @type {Array<string>}
     * @memberof V1ServiceSpec
     */
    ipFamilies?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    ipFamilyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    loadBalancerClass?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    loadBalancerIP?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ServiceSpec
     */
    loadBalancerSourceRanges?: Array<string>;
    /**
     * 
     * @type {Array<V1ServicePort>}
     * @memberof V1ServiceSpec
     */
    ports?: Array<V1ServicePort>;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServiceSpec
     */
    publishNotReadyAddresses?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof V1ServiceSpec
     */
    selector?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    sessionAffinity?: string;
    /**
     * 
     * @type {V1SessionAffinityConfig}
     * @memberof V1ServiceSpec
     */
    sessionAffinityConfig?: V1SessionAffinityConfig;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceSpec
     */
    type?: string;
}

export function V1ServiceSpecFromJSON(json: any): V1ServiceSpec {
    return V1ServiceSpecFromJSONTyped(json, false);
}

export function V1ServiceSpecFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ServiceSpec {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allocateLoadBalancerNodePorts': !exists(json, 'allocateLoadBalancerNodePorts') ? undefined : json['allocateLoadBalancerNodePorts'],
        'clusterIP': !exists(json, 'clusterIP') ? undefined : json['clusterIP'],
        'clusterIPs': !exists(json, 'clusterIPs') ? undefined : json['clusterIPs'],
        'externalIPs': !exists(json, 'externalIPs') ? undefined : json['externalIPs'],
        'externalName': !exists(json, 'externalName') ? undefined : json['externalName'],
        'externalTrafficPolicy': !exists(json, 'externalTrafficPolicy') ? undefined : json['externalTrafficPolicy'],
        'healthCheckNodePort': !exists(json, 'healthCheckNodePort') ? undefined : json['healthCheckNodePort'],
        'internalTrafficPolicy': !exists(json, 'internalTrafficPolicy') ? undefined : json['internalTrafficPolicy'],
        'ipFamilies': !exists(json, 'ipFamilies') ? undefined : json['ipFamilies'],
        'ipFamilyPolicy': !exists(json, 'ipFamilyPolicy') ? undefined : json['ipFamilyPolicy'],
        'loadBalancerClass': !exists(json, 'loadBalancerClass') ? undefined : json['loadBalancerClass'],
        'loadBalancerIP': !exists(json, 'loadBalancerIP') ? undefined : json['loadBalancerIP'],
        'loadBalancerSourceRanges': !exists(json, 'loadBalancerSourceRanges') ? undefined : json['loadBalancerSourceRanges'],
        'ports': !exists(json, 'ports') ? undefined : ((json['ports'] as Array<any>).map(V1ServicePortFromJSON)),
        'publishNotReadyAddresses': !exists(json, 'publishNotReadyAddresses') ? undefined : json['publishNotReadyAddresses'],
        'selector': !exists(json, 'selector') ? undefined : json['selector'],
        'sessionAffinity': !exists(json, 'sessionAffinity') ? undefined : json['sessionAffinity'],
        'sessionAffinityConfig': !exists(json, 'sessionAffinityConfig') ? undefined : V1SessionAffinityConfigFromJSON(json['sessionAffinityConfig']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function V1ServiceSpecToJSON(value?: V1ServiceSpec | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allocateLoadBalancerNodePorts': value.allocateLoadBalancerNodePorts,
        'clusterIP': value.clusterIP,
        'clusterIPs': value.clusterIPs,
        'externalIPs': value.externalIPs,
        'externalName': value.externalName,
        'externalTrafficPolicy': value.externalTrafficPolicy,
        'healthCheckNodePort': value.healthCheckNodePort,
        'internalTrafficPolicy': value.internalTrafficPolicy,
        'ipFamilies': value.ipFamilies,
        'ipFamilyPolicy': value.ipFamilyPolicy,
        'loadBalancerClass': value.loadBalancerClass,
        'loadBalancerIP': value.loadBalancerIP,
        'loadBalancerSourceRanges': value.loadBalancerSourceRanges,
        'ports': value.ports === undefined ? undefined : ((value.ports as Array<any>).map(V1ServicePortToJSON)),
        'publishNotReadyAddresses': value.publishNotReadyAddresses,
        'selector': value.selector,
        'sessionAffinity': value.sessionAffinity,
        'sessionAffinityConfig': V1SessionAffinityConfigToJSON(value.sessionAffinityConfig),
        'type': value.type,
    };
}

