/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1VolumeDevice
 */
export interface V1VolumeDevice {
    /**
     * devicePath is the path inside of the container that the device will be mapped to.
     * @type {string}
     * @memberof V1VolumeDevice
     */
    devicePath?: string;
    /**
     * name must match the name of a persistentVolumeClaim in the pod
     * @type {string}
     * @memberof V1VolumeDevice
     */
    name?: string;
}

export function V1VolumeDeviceFromJSON(json: any): V1VolumeDevice {
    return V1VolumeDeviceFromJSONTyped(json, false);
}

export function V1VolumeDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1VolumeDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'devicePath': !exists(json, 'devicePath') ? undefined : json['devicePath'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function V1VolumeDeviceToJSON(value?: V1VolumeDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'devicePath': value.devicePath,
        'name': value.name,
    };
}

