/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/lookout/lookout.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1PodAffinityTerm,
    V1PodAffinityTermFromJSON,
    V1PodAffinityTermFromJSONTyped,
    V1PodAffinityTermToJSON,
} from './V1PodAffinityTerm';
import {
    V1WeightedPodAffinityTerm,
    V1WeightedPodAffinityTermFromJSON,
    V1WeightedPodAffinityTermFromJSONTyped,
    V1WeightedPodAffinityTermToJSON,
} from './V1WeightedPodAffinityTerm';

/**
 * 
 * @export
 * @interface V1PodAntiAffinity
 */
export interface V1PodAntiAffinity {
    /**
     * The scheduler will prefer to schedule pods to nodes that satisfy
     * the anti-affinity expressions specified by this field, but it may choose
     * a node that violates one or more of the expressions. The node that is
     * most preferred is the one with the greatest sum of weights, i.e.
     * for each node that meets all of the scheduling requirements (resource
     * request, requiredDuringScheduling anti-affinity expressions, etc.),
     * compute a sum by iterating through the elements of this field and adding
     * "weight" to the sum if the node has pods which matches the corresponding podAffinityTerm; the
     * node(s) with the highest sum are the most preferred.
     * +optional
     * @type {Array<V1WeightedPodAffinityTerm>}
     * @memberof V1PodAntiAffinity
     */
    preferredDuringSchedulingIgnoredDuringExecution?: Array<V1WeightedPodAffinityTerm>;
    /**
     * If the anti-affinity requirements specified by this field are not met at
     * scheduling time, the pod will not be scheduled onto the node.
     * If the anti-affinity requirements specified by this field cease to be met
     * at some point during pod execution (e.g. due to a pod label update), the
     * system may or may not try to eventually evict the pod from its node.
     * When there are multiple elements, the lists of nodes corresponding to each
     * podAffinityTerm are intersected, i.e. all terms must be satisfied.
     * +optional
     * @type {Array<V1PodAffinityTerm>}
     * @memberof V1PodAntiAffinity
     */
    requiredDuringSchedulingIgnoredDuringExecution?: Array<V1PodAffinityTerm>;
}

export function V1PodAntiAffinityFromJSON(json: any): V1PodAntiAffinity {
    return V1PodAntiAffinityFromJSONTyped(json, false);
}

export function V1PodAntiAffinityFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PodAntiAffinity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preferredDuringSchedulingIgnoredDuringExecution': !exists(json, 'preferredDuringSchedulingIgnoredDuringExecution') ? undefined : ((json['preferredDuringSchedulingIgnoredDuringExecution'] as Array<any>).map(V1WeightedPodAffinityTermFromJSON)),
        'requiredDuringSchedulingIgnoredDuringExecution': !exists(json, 'requiredDuringSchedulingIgnoredDuringExecution') ? undefined : ((json['requiredDuringSchedulingIgnoredDuringExecution'] as Array<any>).map(V1PodAffinityTermFromJSON)),
    };
}

export function V1PodAntiAffinityToJSON(value?: V1PodAntiAffinity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preferredDuringSchedulingIgnoredDuringExecution': value.preferredDuringSchedulingIgnoredDuringExecution === undefined ? undefined : ((value.preferredDuringSchedulingIgnoredDuringExecution as Array<any>).map(V1WeightedPodAffinityTermToJSON)),
        'requiredDuringSchedulingIgnoredDuringExecution': value.requiredDuringSchedulingIgnoredDuringExecution === undefined ? undefined : ((value.requiredDuringSchedulingIgnoredDuringExecution as Array<any>).map(V1PodAffinityTermToJSON)),
    };
}

