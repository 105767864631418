/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V1ConfigMapEnvSource,
    V1ConfigMapEnvSourceFromJSON,
    V1ConfigMapEnvSourceFromJSONTyped,
    V1ConfigMapEnvSourceToJSON,
} from './V1ConfigMapEnvSource';
import {
    V1SecretEnvSource,
    V1SecretEnvSourceFromJSON,
    V1SecretEnvSourceFromJSONTyped,
    V1SecretEnvSourceToJSON,
} from './V1SecretEnvSource';

/**
 * EnvFromSource represents the source of a set of ConfigMaps
 * @export
 * @interface V1EnvFromSource
 */
export interface V1EnvFromSource {
    /**
     * 
     * @type {V1ConfigMapEnvSource}
     * @memberof V1EnvFromSource
     */
    configMapRef?: V1ConfigMapEnvSource;
    /**
     * An optional identifier to prepend to each key in the ConfigMap. Must be a C_IDENTIFIER.
     * +optional
     * @type {string}
     * @memberof V1EnvFromSource
     */
    prefix?: string;
    /**
     * 
     * @type {V1SecretEnvSource}
     * @memberof V1EnvFromSource
     */
    secretRef?: V1SecretEnvSource;
}

export function V1EnvFromSourceFromJSON(json: any): V1EnvFromSource {
    return V1EnvFromSourceFromJSONTyped(json, false);
}

export function V1EnvFromSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1EnvFromSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'configMapRef': !exists(json, 'configMapRef') ? undefined : V1ConfigMapEnvSourceFromJSON(json['configMapRef']),
        'prefix': !exists(json, 'prefix') ? undefined : json['prefix'],
        'secretRef': !exists(json, 'secretRef') ? undefined : V1SecretEnvSourceFromJSON(json['secretRef']),
    };
}

export function V1EnvFromSourceToJSON(value?: V1EnvFromSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configMapRef': V1ConfigMapEnvSourceToJSON(value.configMapRef),
        'prefix': value.prefix,
        'secretRef': V1SecretEnvSourceToJSON(value.secretRef),
    };
}

