/* tslint:disable */
/* eslint-disable */
/**
 * pkg/api/event.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiJobSetInfo,
    ApiJobSetInfoFromJSON,
    ApiJobSetInfoFromJSONTyped,
    ApiJobSetInfoToJSON,
} from './ApiJobSetInfo';

/**
 * 
 * @export
 * @interface ApiQueueInfo
 */
export interface ApiQueueInfo {
    /**
     * 
     * @type {Array<ApiJobSetInfo>}
     * @memberof ApiQueueInfo
     */
    activeJobSets?: Array<ApiJobSetInfo>;
    /**
     * 
     * @type {string}
     * @memberof ApiQueueInfo
     */
    name?: string;
}

export function ApiQueueInfoFromJSON(json: any): ApiQueueInfo {
    return ApiQueueInfoFromJSONTyped(json, false);
}

export function ApiQueueInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiQueueInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeJobSets': !exists(json, 'activeJobSets') ? undefined : ((json['activeJobSets'] as Array<any>).map(ApiJobSetInfoFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function ApiQueueInfoToJSON(value?: ApiQueueInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeJobSets': value.activeJobSets === undefined ? undefined : ((value.activeJobSets as Array<any>).map(ApiJobSetInfoToJSON)),
        'name': value.name,
    };
}

